import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ospitalita',
  templateUrl: './ospitalita.component.html',
  styleUrls: ['./ospitalita.component.scss']
})
export class OspitalitaComponent implements OnInit {
  constructor(private router: Router) {

  }

  ngOnInit() {
    window.scrollTo(0, 0);
    console.log("url: " + this.router.url);

  }

}
