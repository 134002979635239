import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cleaning-detergenza',
  templateUrl: './cleaning-detergenza.component.html',
  styleUrls: ['./cleaning-detergenza.component.scss']
})
export class CleaningDetergenzaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0,0)
  }

}
