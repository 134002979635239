import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-curaeriposo',
  templateUrl: './curaeriposo.component.html',
  styleUrls: ['./curaeriposo.component.scss']
})
export class CuraeriposoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
