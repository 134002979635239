import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
    selector: 'app-login-admin',
    templateUrl: './login-component.html',
    styleUrls: ['./login-component.scss']
})
export class LoginComponent implements OnInit {
    hide = true;

    url = 'http://localhost:3000/login';
    constructor(private http: HttpClient, private router: Router) {
        this.hide = true;

    }


    loginForm = new FormGroup({
        user: new FormControl(null, Validators.required),
        psw: new FormControl(null, Validators.required)
    });
    ngOnInit() {
        sessionStorage.setItem("token", null)
        this.hide = true;

    }

    showHidePsw() {
        this.hide = !this.hide;
    }

    logIn() {

        if (this.loginForm.valid) {
            let ps = this.loginForm.value.psw;

            // let user = {
            //     username: this.loginForm.value.user.toString(),
            //     psw: this.md5.appendStr(ps.toString()).end(),
            // }
            let user = {
                username: this.loginForm.value.user.toString(),
                password: ps
            }
            // console.log(user);
            // console.log(user.psw);
            return this.http.post(this.url, user).subscribe(
                data => {
                    let res: any = data;
                    // console.log(res.accessToken);
                    sessionStorage.setItem('token', res.accessToken);

                    console.log(
                        'call done'
                    );
                    // console.log(res.accessToken);
                    // if(res.accessToken!=''||res.accessToken!=null){
                    this.router.navigateByUrl("/admin/dashboard");
                    // }

                    // console.log(sessionStorage.getItem('token'));
                    // this.operatoriFilter=this.operatori;
                    //  console.log(data);
                }, err => {
                    console.log("errore LOGIN")
                    // Swal.fire({
                    //     title: 'ATTENZIONE!',
                    //     text: 'Controlla le tue credenziali e riprova',
                    //     icon: 'warning',
                    //     confirmButtonText: 'RIPROVA'
                    // });

                })
        } else {
            //TODO ERRORI
        }

    }


}
