import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hotel-colazione',
  templateUrl: './hotel-colazione.component.html',
  styleUrls: ['./hotel-colazione.component.scss']
})
export class HotelColazioneComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
