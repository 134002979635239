import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, AfterViewInit, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { RouterLink } from '@angular/router';
import { Router } from 'express';

@Component({
    selector: 'app-admin-news-list',
    templateUrl: './news.list.html',
    styleUrls: ['./news.list.scss']
})



export class AdminNewsComponent implements OnInit {
    newsList = [];
    loading: boolean;
    baseUrl = 'http://localhost:3000/'
    url = 'http://localhost:3000/news'
    constructor(private http: HttpClient) { }
    dataSource = new MatTableDataSource<News>(this.newsList);
    displayedColumns: string[] = ['Titolo', 'Data', 'Autore', 'Stato', 'Edit'];


    ngOnInit() {
        this.loading = true;
        this.getNews().subscribe((data) => {
            this.newsList = data;
            console.log(this.newsList)
            this.dataSource.data = this.newsList;
            this.loading = false;
        });

    }

    refreshNews() {
        this.loading = true;
        this.getNews().subscribe((data) => {
            this.newsList = data;
            console.log(this.newsList)
            this.dataSource.data = this.newsList;
            this.loading = false;
        });
    }

    changeStatus(id, mostra) {
        console.log(id);
        console.log(mostra);

        this.editNewsStatus({
            mostra: !mostra,
            id: id
        });

    }

    editNews(id) {
        console.log(id)
        // this.router.navigate([`class/${classId}/enrollment/${4545455}`]);

    }


    editNewsStatus(obj) {

        return this.http.put<any>(this.baseUrl + "news-status", obj).subscribe(
            data => {
                let res: any = data;
                console.log(
                    'call done'
                );
                console.log(res)
                this.refreshNews()

            })
    }
    getNews() {
        let headers = new HttpHeaders();
        headers = headers.set('Authorization', 'Bearer ' + sessionStorage.getItem("token"));
        return this.http.get<News[]>(this.url, { headers });

    }

}
export interface News {
    id: number,
    titolo: string,
    testo: string,
    data: string,
    autore: string,
    mostra: boolean,
    img_evidenza: string
}
