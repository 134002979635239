import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { MenubarComponent } from './components/menubar/menubar.component';
import { AboutComponent } from './components/about/about.component';
import { AmbientiComponent } from './components/ambienti/ambienti.component';
import { FooterComponent } from './components/footer/footer.component';
import { DettaglioAmbienteComponent } from './components/dettaglio-ambiente/dettaglio-ambiente.component';
import { ProgettiComponent } from './components/progetti/progetti.component';
import { ServiziComponent } from './components/servizi/servizi.component';
import { NewsComponent } from './components/news/news.component';
import { ContattiComponent } from './components/contatti/contatti.component';
import { OspitalitaComponent } from './components/ospitalita/ospitalita.component';
import { RistorazioneComponent } from './components/ristorazione/ristorazione.component';
import { SanitarioAssistenzialeComponent } from './components/sanitario-assistenziale/sanitario-assistenziale.component';
import { FoodComponent } from './components/food/food.component';
import { OfficeComponent } from './components/office/office.component';
import { ConsulenzeComponent } from './components/consulenze/consulenze.component';
import { CucineProgEsplosioneComponent } from './components/cucine-prog-esplosione/cucine-prog-esplosione.component';
import { LavaProgEsplosioneComponent } from './components/lava-prog-esplosione/lava-prog-esplosione.component';
import { DehoreProgEsplComponent } from './components/dehore-prog-espl/dehore-prog-espl.component';
import { FormPreventivoComponent } from './components/form-preventivo/form-preventivo.component';
import { ConsTComponent } from './components/cons-t/cons-t.component';
import { ConsTMieleComponent } from './components/cons-t-miele/cons-t-miele.component';
import { NuoveApertureComponent } from './components/nuove-aperture/nuove-aperture.component';
import { PorcellaneComponent } from './components/porcellane/porcellane.component';
import { PosateriaComponent } from './components/posateria/posateria.component';
import { BarComponent } from './components/bar/bar.component';
import { RistoAccessoriCucinaComponent } from './components/risto-accessori-cucina/risto-accessori-cucina.component';
import { VetriComponent } from './components/vetri/vetri.component';
import { RistTavolaComponent } from './components/rist-tavola/rist-tavola.component';
import { ResidenceComponent } from './components/residence/residence.component';
import { FacilityComponent } from './components/facility/facility.component';
import { FmAtComponent } from './components/fm-at/fm-at.component';
import { FmAtMieleComponent } from './components/fm-at-miele/fm-at-miele.component';
import { ItaliaComponent } from './components/italia/italia.component';
import { UkComponent } from './components/uk/uk.component';
import { FranciaComponent } from './components/francia/francia.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MatDialogModule } from '@angular/material/dialog';
import { RisLavaggioComponent } from './components/ris-lavaggio/ris-lavaggio.component';
import { FreddoComponent } from './components/freddo/freddo.component';
import { AsportoComponent } from './components/asporto/asporto.component';
import { CaffetteriaRistoComponent } from './components/caffetteria-risto/caffetteria-risto.component';
import { CleaningDetergenzaComponent } from './components/cleaning-detergenza/cleaning-detergenza.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { CleaningSanificaComponent } from './components/cleaning-sanifica/cleaning-sanifica.component';
import { MacchinePuliziaComponent } from './components/macchine-pulizia/macchine-pulizia.component';
import { DeodorantiComponent } from './components/deodoranti/deodoranti.component';
import { AttrezziPuliziaComponent } from './components/attrezzi-pulizia/attrezzi-pulizia.component';
import { AlimCotturaComponent } from './components/alim-cottura/alim-cottura.component';
import { AlimAsportoComponent } from './components/alim-asporto/alim-asporto.component';
import { AlimLavaggioComponent } from './components/alim-lavaggio/alim-lavaggio.component';
import { AlimConsStoccaggioComponent } from './components/alim-cons-stoccaggio/alim-cons-stoccaggio.component';
import { AlimPrepDinamicaComponent } from './components/alim-prep-dinamica/alim-prep-dinamica.component';
import { HotelPersonalizzazioniComponent } from './components/hotel-personalizzazioni/hotel-personalizzazioni.component';
import { HotelCortesiaComponent } from './components/hotel-cortesia/hotel-cortesia.component';
import { HotelLavanderiaComponent } from './components/hotel-lavanderia/hotel-lavanderia.component';
import { HotelColazioneComponent } from './components/hotel-colazione/hotel-colazione.component';
import { HotelBagnoComponent } from './components/hotel-bagno/hotel-bagno.component';
import { HotelPuliziaComponent } from './components/hotel-pulizia/hotel-pulizia.component';
import { OutdoorComponent } from './components/outdoor/outdoor.component';
import { CuraeriposoComponent } from './components/curaeriposo/curaeriposo.component';
import { LavanderiaComponent } from './components/anziani/lavanderia/lavanderia.component';
import { CucinaComponent } from './components/anziani/cucina/cucina.component';
import { DetergenzaComponent } from './components/anziani/detergenza/detergenza.component';
import { BagniComponent } from './components/anziani/bagni/bagni.component';
import { OutdoorgiardiniComponent } from './components/outdoor/outdoorgiardini/outdoorgiardini.component';
import { OutdoordehoreComponent } from './components/outdoor/outdoordehore/outdoordehore.component';
import { HotelCameraComponent } from './components/hotel-camera/hotel-camera.component';
import { HospitalMonousoComponent } from './components/hospital-monouso/hospital-monouso.component';
import { LandingCatalogoComponent } from './components/landing-catalogo/landing-catalogo.component';
import { BcarrowComponent } from './components/bcarrow/bcarrow.component';
import { RinnovolocaliComponent } from './components/rinnovolocali/rinnovolocali.component';
import { LoadermsgComponent } from './components/loadermsg/loadermsg.component';
import { MsgritornoComponent } from './components/msgritorno/msgritorno.component';
import { DialogcontattiComponent } from './components/dialogcontatti/dialogcontatti.component';
import { InformativaPrivacyComponent } from './components/informativa-privacy/informativa-privacy.component';
import { CookiePolicyComponent } from './components/cookie-policy/cookie-policy.component';
import { DettaglioNews } from './components/dettagio-news/dettaglio-news';
import { LoginComponent } from './components/admin/login/login-component';
import { JwtModule } from '@auth0/angular-jwt';
import { AuthGuardService } from './auth-guard';
import { DashAdminComponent } from './components/admin/dashboard/dash-admin.component';
import { AdminMenuComponent } from './components/admin/menu/admin-menu';
import { AdminNewsComponent } from './components/admin/news/news.list';
import { EditorModule } from "@tinymce/tinymce-angular";

import { MatButtonModule, MatFormFieldModule, MatIconModule, MatInput, MatInputModule, MatOptionModule, MatSelect, MatSelectModule, MatTableModule } from '@angular/material';
import { AdminAddNewsComponent } from './components/admin/news/add.news';
import { EditNewsComponent } from './components/admin/news/edit.news';
import { DialogAddImgComponent } from './components/admin/news/dialog/add.img.dialog';
export function tokenGetter() {
  return sessionStorage.getItem('token');
}
@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    MenubarComponent,
    AboutComponent,
    AmbientiComponent,
    FooterComponent,
    DettaglioAmbienteComponent,
    ProgettiComponent,
    ServiziComponent,
    NewsComponent,
    ContattiComponent,
    OspitalitaComponent,
    RistorazioneComponent,
    SanitarioAssistenzialeComponent,
    FoodComponent,
    OfficeComponent,
    ConsulenzeComponent,
    CucineProgEsplosioneComponent,
    LavaProgEsplosioneComponent,
    DehoreProgEsplComponent,
    FormPreventivoComponent,
    ConsTComponent,
    ConsTMieleComponent,
    NuoveApertureComponent,
    PorcellaneComponent,
    PosateriaComponent,
    BarComponent,
    RistoAccessoriCucinaComponent,
    VetriComponent,
    RistTavolaComponent,
    ResidenceComponent,
    FacilityComponent,
    FmAtComponent,
    FmAtMieleComponent,
    ItaliaComponent,
    UkComponent,
    FranciaComponent,
    RisLavaggioComponent,
    FreddoComponent,
    AsportoComponent,
    CaffetteriaRistoComponent,
    CleaningDetergenzaComponent,
    NotfoundComponent,
    CleaningSanificaComponent,
    MacchinePuliziaComponent,
    DeodorantiComponent,
    AttrezziPuliziaComponent,
    AlimCotturaComponent,
    AlimAsportoComponent,
    AlimLavaggioComponent,
    AlimConsStoccaggioComponent,
    AlimPrepDinamicaComponent,
    HotelPersonalizzazioniComponent,
    HotelCortesiaComponent,
    HotelLavanderiaComponent,
    HotelColazioneComponent,
    HotelBagnoComponent,
    HotelPuliziaComponent,
    OutdoorComponent,
    CuraeriposoComponent,
    LavanderiaComponent,
    CucinaComponent,
    DetergenzaComponent,
    BagniComponent,
    OutdoorgiardiniComponent,
    OutdoordehoreComponent,
    HotelCameraComponent,
    HospitalMonousoComponent,
    LandingCatalogoComponent,
    BcarrowComponent,
    RinnovolocaliComponent,
    LoadermsgComponent,
    MsgritornoComponent,
    DialogcontattiComponent,
    InformativaPrivacyComponent,
    CookiePolicyComponent,
    DettaglioNews,
    LoginComponent,
    DashAdminComponent,
    AdminMenuComponent,
    AdminNewsComponent,
    AdminAddNewsComponent,
    EditNewsComponent,
    DialogAddImgComponent
  ],
  imports: [
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        // whitelistedDomains: ["mbk.albedo.it"],
        // blacklistedRoutes: ["example.com/examplebadroute/"]
      }
    }),
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    EditorModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatOptionModule,
    MatSelectModule
  ],
  entryComponents: [DialogcontattiComponent, FormPreventivoComponent, DialogAddImgComponent],
  providers: [AuthGuardService],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
