import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alim-cottura',
  templateUrl: './alim-cottura.component.html',
  styleUrls: ['./alim-cottura.component.scss']
})
export class AlimCotturaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0,0)
  }

}
