import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-outdoordehore',
  templateUrl: './outdoordehore.component.html',
  styleUrls: ['./outdoordehore.component.scss']
})
export class OutdoordehoreComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0,0);

  }

}
