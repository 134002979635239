import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenubarComponent } from './components/menubar/menubar.component';
import { Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent implements OnInit {

  @Input() ll: boolean;

  title = 'reano-angular';
  loader = false;

  constructor(private router: Router, @Inject(DOCUMENT) public document, private metaTagService: Meta) {


  }

  ngOnInit() {
    if (this.router.url == "/") {
      console.log("url: " + this.router.url)
      this.metaTagService.addTags([
        { name: 'description', content: 'Reano S.R.L. dal 1964 fornisce hotel, ristoranti, case di riposo, scuole, realtà del settore alimetnare con professionalità e impegno. Si occupa anche di progettazione di cucine, lavanderie o dehors.' }
      ]);
    }
    this.getName()
  }
  public doSomething(date: any): void {
    console.log('Picked date: ', date);
  }
  public loading(status) {
    this.loader = status
  }
  isHomepage: boolean;
  getName() {
    if (this.router.url != "") {
      this.isHomepage = false
    } else {
      this.isHomepage = true;
      this.metaTagService.updateTag(
        { name: 'description', content: 'Reano S.R.L. dal 1964 fornisce hotel, ristoranti, case di riposo, scuole, realtà del settore alimetnare con professionalità e impegno. Si occupa anche di progettazione di cucine, lavanderie o dehors.' }
      );
    }
  }
}
