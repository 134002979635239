import { Component, OnInit, SecurityContext } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { FormPreventivoComponent } from '../form-preventivo/form-preventivo.component';

@Component({
  selector: 'app-landing-catalogo',
  templateUrl: './landing-catalogo.component.html',
  styleUrls: ['./landing-catalogo.component.scss']
})
export class LandingCatalogoComponent implements OnInit {
  variabile;
  sub;
  path;
  pdf;
  breadcrumbs;
  urlNonno = '';
  urlPadre;
  video = false;
  urlpdf = [];
  safeUrl = [];
  finalSurl = [];
  urlVideo = [];
  baseUrlPdf = '../../../assets/pdf/';
  baseUrlImg = '../../../assets/img/';
  constructor(private strada: ActivatedRoute, private _sanitizer: DomSanitizer) {
  }

  toggolino = false;
  ics = false;
  apriForm() {
    if (!this.toggolino) {
      this.ics = !this.ics
      document.getElementById("fc").classList.add("muovi")
      this.toggolino = !this.toggolino;
    } else {
      document.getElementById("fc").classList.remove("muovi")
      this.toggolino = !this.toggolino;
      this.ics = !this.ics
    }

  }
  apriDialogoContatti() {
    document.querySelector(".mobile-form")[0].style.left = '-80vw';
  }


  ngOnInit() {
    this.urlpdf = [];
    this.sub = this.strada.params.subscribe(params => {
      this.path = params['path'];
      this.pdf = params['pdf'];
    });

    if (this.path == 'cortesia' && this.pdf == 'collezioni') {
      this.breadcrumbs = {
        ambiente: "Hotel",
        sottoambiente: "Cortesia",
        catalogo: "Collezioni Cortesia"
      };

      this.urlNonno = "/hotellerie";
      this.urlPadre = this.urlNonno + "/linea-cortesia";
      this.urlpdf = [
        {
          url: '../../../assets/pdf/LINEA_CORTESIA_Acqua_di_Bolgheri.pdf',
          nome: 'Acqua di Bolgheri',
          img: '../../../assets/img/REANO_LogoRed.svg'
        },
        {
          url: '../../../assets/pdf/LINEA_CORTESIA_Anyah.pdf',
          nome: 'Anyah',
          img: '../../../assets/img/REANO_LogoRed.svg'

        },
        {
          url: '../../../assets/pdf/LINEA_CORTESIA_Canova.pdf',
          nome: 'Canova',
          img: '../../../assets/img/REANO_LogoRed.svg'
        },
        {
          url: '../../../assets/pdf/LINEA_CORTESIA_Da Vinci.pdf',
          nome: 'Da Vinci',
          img: '../../../assets/img/REANO_LogoRed.svg'
        },
        {
          url: '../../../assets/pdf/LINEA_CORTESIA_Eva D\'or.pdf',
          nome: 'Eva D\'or',
          img: '../../../assets/img/REANO_LogoRed.svg'
        },
        {
          url: '../../../assets/pdf/LINEA_CORTESIA_Ever Green.pdf',
          nome: 'Ever Green',
          img: '../../../assets/img/REANO_LogoRed.svg'
        },
        {
          url: '../../../assets/pdf/LINEA_CORTESIA_Sandal_&_Rose.pdf',
          nome: 'Sandal & Rose',
          img: '../../../assets/img/REANO_LogoRed.svg'
        }

      ]
    } else if (this.path == 'ristorazione' && this.pdf == 'tovagliato-monouso') {
      this.breadcrumbs = {
        ambiente: "Ristorazione",
        sottoambiente: "Tavola",
        catalogo: "Tovagliato"
      };

      this.urlNonno = "/ristorazione";
      this.urlPadre = this.urlNonno + "/tavola-buffet";
      let urls = [
        // this.baseUrlPdf + 'Tovaglioli Elegance.pdf',
        // this.baseUrlPdf + 'Tovaglioli Dunisoft.pdf',
        this.baseUrlPdf + 'Portaposate_25.pdf',
        this.baseUrlPdf + 'Tovaglioli cellulosa pt.1_25.pdf',
        this.baseUrlPdf + 'Tovaglioli cellulosa pt.2_25.pdf',
        this.baseUrlPdf + 'Tovaglioli Bio Dunisoft_25.pdf',
        // this.baseUrlPdf + 'Tovaglioli cellulosa.pdf',
        // this.baseUrlPdf + 'Tovaglioli cellulosa piegati 1-8.pdf',
        this.baseUrlPdf + 'Dispenser tovaglioli_25.pdf',
        this.baseUrlPdf + 'Tovaglie_25.pdf',
        this.baseUrlPdf + 'Tovaglie rotolo_25.pdf',
        this.baseUrlPdf + 'Elegance_25.pdf',
        this.baseUrlPdf + 'Gonne plisse buffet_25.pdf',
        this.baseUrlPdf + 'Runner Tete-a-tete_25.pdf',
        this.baseUrlPdf + 'Tovagliette_25.pdf',
        this.baseUrlPdf + 'Sottobicchieri pizzi sottofritti_25.pdf',
        this.baseUrlPdf + 'Il mondo delle candele_25.pdf',
        // this.baseUrlPdf + '14_ACCESSORI.pdf',
        // this.baseUrlPdf + '15_COLLEZIONI_2021.pdf',
        // this.baseUrlPdf + 'Duni COLLEZIONE PROFESSIONALE TOVAGLIATO Autunno-Inverno 2021.pdf',
        // this.baseUrlPdf + 'Accessori.pdf',
        this.baseUrlPdf + 'Collezioni Design Duni 2025.pdf',
        this.baseUrlPdf + 'DUNI CATALOGO TOVAGLIATO 2025.pdf',
        // this.baseUrlPdf + 'CATALOGO DUNI AUTUNNO - INVERNO 2024.pdf',
        // this.baseUrlPdf + 'DUNI Collezione Autunno-Inverno 2023_LOW.pdf',
        // this.baseUrlPdf + 'CATALOGO PROFESSIONAL DUNI  AUTUNNO INVERNO 2022.pdf',
      ];
      let imgs = [
        // this.baseUrlImg + 'REANO_LogoRed.svg',
        // this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        // this.baseUrlImg + 'REANO_LogoRed.svg',
        // this.baseUrlImg + 'REANO_LogoRed.svg',
        // this.baseUrlImg + 'REANO_LogoRed.svg',
        // this.baseUrlImg + 'REANO_LogoRed.svg',
        // this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        // this.baseUrlImg + 'REANO_LogoRed.svg',
        // this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        // 'Elegance',
        // 'Tovaglioli Duni soft',
        'Portaposate',
        'Tovaglioli cellulosa pt.1',
        'Tovaglioli cellulosa pt.2',
        'Tovaglioli Bio-Dunisoft e Dunisoft',
        'Dispenser tovaglioli',
        'Tovaglie',
        'Tovaglie in rotolo',
        'Elegance',
        'Gonne plisse per buffet',
        'Runner tete a tete',
        'Tovagliette',
        'Sottobicchieri, pizzi, sottofritti',
        'Il mondo delle candele ed accessori',
        // 'Accessori',
        // 'Collezioni 2021',
        // 'Collezione Duni Autunno/Inverno 2021',
        // 'Accessori',
        'Collezioni Design Duni 2025',
        'CATALOGO DUNI TOVAGLIATO 2025',
        // 'CATALOGO DUNI AUTUNNO - INVERNO 2024',
        // 'COLLEZIONE AUTUNNO INVERNO 2023',

        // 'Collezioni Duni Autunno Inverno 2022',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'hotel' && this.pdf == 'tovagliato-monouso') {
      this.breadcrumbs = {
        ambiente: "Hotel",
        sottoambiente: "Colazione",
        catalogo: "Tovagliato"
      };

      this.urlNonno = "/hotellerie";
      this.urlPadre = this.urlNonno + "/colazione";
      let urls = [
        // this.baseUrlPdf + 'Tovaglioli Elegance.pdf',
        // this.baseUrlPdf + 'Tovaglioli Dunisoft.pdf',
        this.baseUrlPdf + 'Portaposate_25.pdf',
        this.baseUrlPdf + 'Tovaglioli cellulosa pt.1_25.pdf',
        this.baseUrlPdf + 'Tovaglioli cellulosa pt.2_25.pdf',
        this.baseUrlPdf + 'Tovaglioli Bio Dunisoft_25.pdf',
        // this.baseUrlPdf + 'Tovaglioli cellulosa.pdf',
        // this.baseUrlPdf + 'Tovaglioli cellulosa piegati 1-8.pdf',
        this.baseUrlPdf + 'Dispenser tovaglioli_25.pdf',
        this.baseUrlPdf + 'Tovaglie_25.pdf',
        this.baseUrlPdf + 'Tovaglie rotolo_25.pdf',
        this.baseUrlPdf + 'Elegance_25.pdf',
        this.baseUrlPdf + 'Gonne plisse buffet_25.pdf',
        this.baseUrlPdf + 'Runner Tete-a-tete_25.pdf',
        this.baseUrlPdf + 'Tovagliette_25.pdf',
        this.baseUrlPdf + 'Sottobicchieri pizzi sottofritti_25.pdf',
        this.baseUrlPdf + 'Il mondo delle candele_25.pdf',
        // this.baseUrlPdf + '14_ACCESSORI.pdf',
        // this.baseUrlPdf + '15_COLLEZIONI_2021.pdf',
        // this.baseUrlPdf + 'Duni COLLEZIONE PROFESSIONALE TOVAGLIATO Autunno-Inverno 2021.pdf',
        // this.baseUrlPdf + 'Accessori.pdf',
        this.baseUrlPdf + 'Collezioni Design Duni 2025.pdf',
        this.baseUrlPdf + 'DUNI CATALOGO TOVAGLIATO 2025.pdf',
        // this.baseUrlPdf + 'CATALOGO DUNI AUTUNNO - INVERNO 2024.pdf',
        // this.baseUrlPdf + 'DUNI Collezione Autunno-Inverno 2023_LOW.pdf',
        // this.baseUrlPdf + 'CATALOGO PROFESSIONAL DUNI  AUTUNNO INVERNO 2022.pdf',
      ];
      let imgs = [
        // this.baseUrlImg + 'REANO_LogoRed.svg',
        // this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        // this.baseUrlImg + 'REANO_LogoRed.svg',
        // this.baseUrlImg + 'REANO_LogoRed.svg',
        // this.baseUrlImg + 'REANO_LogoRed.svg',
        // this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        // this.baseUrlImg + 'REANO_LogoRed.svg',
        // this.baseUrlImg + 'REANO_LogoRed.svg',
        // this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        // 'Elegance',
        // 'Tovaglioli Duni soft',
        'Portaposate',
        'Tovaglioli cellulosa pt.1',
        'Tovaglioli cellulosa pt.2',
        'Tovaglioli Bio-Dunisoft e Dunisoft',
        'Dispenser tovaglioli',
        'Tovaglie',
        'Tovaglie in rotolo',
        'Elegance',
        'Gonne plisse per buffet',
        'Runner tete a tete',
        'Tovagliette',
        'Sottobicchieri, pizzi, sottofritti',
        'Il mondo delle candele ed accessori',
        // 'Accessori',
        // 'Collezioni 2021',
        // 'Collezione Duni Autunno/Inverno 2021',
        // 'Accessori',
        'COLLEZIONI DESIGN DUNI 2025',
        'CATALOGO DUNI TOVAGLIATO 2025',
        // 'CATALOGO DUNI AUTUNNO - INVERNO 2024',
        // 'COLLEZIONE AUTUNNO INVERNO 2023',

        // 'Collezioni Duni Autunno Inverno 2022',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }

    else if (this.path == 'ristorazione' && this.pdf == 'porcellane') {
      this.breadcrumbs = {
        ambiente: "Ristorazione",
        sottoambiente: "Tavola",
        catalogo: "Porcellane"
      };

      this.urlNonno = "/ristorazione";
      this.urlPadre = this.urlNonno + "/tavola-buffet";
      let urls = [
        this.baseUrlPdf + 'POR_01 PORCELLANE,GURAL,COSTAVERDE,LUBIANA,PORVASAL.pdf',
        this.baseUrlPdf + 'POR_02 CHURCHILL BIANCO.pdf',
        this.baseUrlPdf + 'POR_03 CHURCHILL STONECAST-COLORE.pdf',
        this.baseUrlPdf + 'POR_04 COLLEZIONI-MISTE.pdf',
        // this.baseUrlPdf + 'POR_05 COLLEZIONI SANGO - LIONESS.pdf',
        this.baseUrlPdf + 'POR_06 SOTTOPIATTI.pdf',
        this.baseUrlPdf + 'POR_07 ASSORTIMENTO INSALATIERE, PIROFILE, VARIE.pdf',
        this.baseUrlPdf + 'Collezione LIONESS.pdf',
        this.baseUrlPdf + 'Collezione SANGO.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        // this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'Porcellane, Gural, Costaverde, Lubiana, Porvasal',
        'Churchill bianco',
        'Churchill stonecast-colore',
        'Collezioni miste',
        // 'Collezioni sango-lioness',
        'Sottopiatti',
        'Assortimento insalatiere, pirofile, varie',
        'Collezione LIONESS',
        'Collezione SANGO',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    } else if (this.path == 'ristorazione' && this.pdf == 'posateria') {
      this.breadcrumbs = {
        ambiente: "Ristorazione",
        sottoambiente: "Tavola",
        catalogo: "Posateria"
      };

      this.urlNonno = "/ristorazione";
      this.urlPadre = this.urlNonno + "/tavola-buffet";
      let urls = [
        this.baseUrlPdf + 'POS_01 POSATE-COLLEZIONI.pdf',
        this.baseUrlPdf + 'POS_02 POSATERIA - CHURCHILL.pdf',
        this.baseUrlPdf + 'POS_03 COMPLEMENTI-POSATE.pdf'
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Collezioni',
        'Churchill',
        'Complementi posate'
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'hotel' && this.pdf == 'essicatoi') {
      this.breadcrumbs = {
        ambiente: "Hotel",
        sottoambiente: "Lavanderia",
        catalogo: "Essicatoi"
      };

      this.urlNonno = "/hotellerie";
      this.urlPadre = this.urlNonno + "/lavanderia";
      let urls = [
        this.baseUrlPdf + 'ESSICCATOI  Miele Professional.pdf',
        this.baseUrlPdf + 'EnergyControl Ottimizzazione Energetica.pdf',
        this.baseUrlPdf + 'Sistemi dosaggio lavanderia.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'ESSICCATOI Miele Professional',
        'EnergyControl Ottimizzazione Energetica',
        'Sistemi dosaggio lavanderia',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'hospital' && this.pdf == 'essicatoi') {
      this.breadcrumbs = {
        ambiente: "Hospital",
        sottoambiente: "Lavanderia",
        catalogo: "Essicatoi"
      };

      this.urlNonno = "/hospital";
      this.urlPadre = this.urlNonno + "/lavanderia";
      let urls = [
        this.baseUrlPdf + 'ESSICCATOI  Miele Professional.pdf',
        this.baseUrlPdf + 'EnergyControl Ottimizzazione Energetica.pdf',
        this.baseUrlPdf + 'Sistemi dosaggio lavanderia.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'ESSICCATOI Miele Professional',
        'EnergyControl Ottimizzazione Energetica',
        'Sistemi dosaggio lavanderia',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'hotel' && this.pdf == 'mangani') {
      this.breadcrumbs = {
        ambiente: "Hotel",
        sottoambiente: "Lavanderia",
        catalogo: "Mangani"
      };

      this.urlNonno = "/hotellerie";
      this.urlPadre = this.urlNonno + "/lavanderia";
      let urls = [
        this.baseUrlPdf + 'MANGANI Miele Professional.pdf',
        this.baseUrlPdf + 'Sistemi dosaggio lavanderia.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'MANGANI Miele Professional',
        'Sistemi dosaggio lavanderia',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'hospital' && this.pdf == 'mangani') {
      this.breadcrumbs = {
        ambiente: "Hospital",
        sottoambiente: "Lavanderia",
        catalogo: "Mangani"
      };

      this.urlNonno = "/hospital";
      this.urlPadre = this.urlNonno + "/lavanderia";
      let urls = [
        this.baseUrlPdf + 'MANGANI Miele Professional.pdf',
        this.baseUrlPdf + 'Sistemi dosaggio lavanderia.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'MANGANI Miele Professional',
        'Sistemi dosaggio lavanderia',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'hotel' && this.pdf == 'lavatrici') {
      this.breadcrumbs = {
        ambiente: "Hotel",
        sottoambiente: "Lavanderia",
        catalogo: "Lavatrici"
      };

      this.urlNonno = "/hotellerie";
      this.urlPadre = this.urlNonno + "/lavanderia";
      let urls = [
        this.baseUrlPdf + 'LAVATRICI Miele Professional.pdf',
        this.baseUrlPdf + 'Sistemi dosaggio lavanderia.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'LAVATRICI Miele Professional',
        'Sistemi dosaggio lavanderia',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'hospital' && this.pdf == 'lavatrici') {
      this.breadcrumbs = {
        ambiente: "Hospital",
        sottoambiente: "Lavanderia",
        catalogo: "Lavatrici"
      };

      this.urlNonno = "/hospital";
      this.urlPadre = this.urlNonno + "/lavanderia";
      let urls = [
        this.baseUrlPdf + 'LAVATRICI Miele Professional.pdf',
        this.baseUrlPdf + 'Sistemi dosaggio lavanderia.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'LAVATRICI Miele Professional',
        'Sistemi dosaggio lavanderia',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'ristorazione' && this.pdf == 'caffetteria-accessori') {
      this.breadcrumbs = {
        ambiente: "Ristorazione",
        sottoambiente: "Caffetteria e Bar",
        catalogo: "Accessori"
      };

      this.urlNonno = "/ristorazione";
      this.urlPadre = this.urlNonno + "/caffetteria";
      let urls = [
        this.baseUrlPdf + '01 Bricchi inox.pdf',
        this.baseUrlPdf + '02 Accessori e posate.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'Bricchi inox',
        'Accessori e posate',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'ristorazione' && this.pdf == 'caffetteria-espositori') {
      this.breadcrumbs = {
        ambiente: "Ristorazione",
        sottoambiente: "Caffetteria e Bar",
        catalogo: "Espositori"
      };

      this.urlNonno = "/ristorazione";
      this.urlPadre = this.urlNonno + "/caffetteria";
      let urls = [
        this.baseUrlPdf + '01 Alzate ed espositori.pdf',
        this.baseUrlPdf + '02 Vetrine espositive.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'Alzate ed espositori',
        'Vetrine espositive',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'ristorazione' && this.pdf == 'vetri-cristalli') {
      this.breadcrumbs = {
        ambiente: "Ristorazione",
        sottoambiente: "Tavola",
        catalogo: "Vetri e cristalli"
      };

      this.urlNonno = "/ristorazione";
      this.urlPadre = this.urlNonno + "/tavola-buffet";
      let urls = [
        this.baseUrlPdf + '01 Calici e collezioni.pdf',
        this.baseUrlPdf + '02 Brocche e decanter.pdf',
        this.baseUrlPdf + '03 Bicchieri e colori.pdf',
        this.baseUrlPdf + '04 Barattoli e creativita.pdf',
        this.baseUrlPdf + '05 Bar e gelati.pdf',
        this.baseUrlPdf + '06 Birra.pdf',
        this.baseUrlPdf + '07 Caraffe e bottiglie.pdf',
        this.baseUrlPdf + '08 Selezione Bicchieri RCR.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'Calici e collezioni',
        'Brocche e decanter',
        'Bicchieri e colori',
        'Barattoli e creatività',
        'Bar e gelati',
        'Birra',
        'Caraffe e bottiglie',
        'Selezione Bicchieri RCR',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'cortesia' && this.pdf == 'accessori') {
      this.breadcrumbs = {
        ambiente: "Hotel",
        sottoambiente: "Cortesia",
        catalogo: "Accessori"
      };

      this.urlNonno = "/hotellerie";
      this.urlPadre = this.urlNonno + "/linea-cortesia";
      let urls = [
        this.baseUrlPdf + 'LINEA CORTESIA - ACCESSORI.pdf'
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Accessori'
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'cortesia' && this.pdf == 'personalizzazioni') {
      this.breadcrumbs = {
        ambiente: "Hotel",
        sottoambiente: "Cortesia",
        catalogo: "Personalizzazioni"
      };

      this.urlNonno = "/hotellerie";
      this.urlPadre = this.urlNonno + "/linea-cortesia";
      let urls = [
        this.baseUrlPdf + 'LINEA CORTESIA - PERSONALIZZAZIONI.pdf',
        this.baseUrlPdf + 'Catalogo generale personalizzati.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'Personalizzazioni',
        'Catalogo generale personalizzati',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'ristorazione' && this.pdf == 'bar') {
      this.breadcrumbs = {
        ambiente: "Ristorazione",
        sottoambiente: "Caffetteria e Bar",
        catalogo: "Bar"
      };

      this.urlNonno = "/ristorazione";
      this.urlPadre = this.urlNonno + "/caffetteria";
      let urls = [
        this.baseUrlPdf + 'COCKTAIL-E-PREPARAZIONI_SPECIALI.pdf',
        this.baseUrlPdf + 'Churchill Beveraging Colour e Shape Innovation.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'COCKTAIL E PREPARAZIONI SPECIALI',
        'Churchill Beveraging Colour e Shape Innovation',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'hospital' && this.pdf == 'stoviglie') {
      this.breadcrumbs = {
        ambiente: "Hospital",
        sottoambiente: "Cucina",
        catalogo: "Stoviglie"
      };

      this.urlNonno = "/hospital";
      this.urlPadre = this.urlNonno + "/cucina";
      let urls = [
        this.baseUrlPdf + 'COLLEZIONE MELAMINA.pdf'
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Collezione Melamina'
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'cleaning' && this.pdf == 'green') {
      this.breadcrumbs = {
        ambiente: "Cleaning",
        sottoambiente: "Detergenza",
        catalogo: "Green"
      };

      this.urlNonno = "/cleaning";
      this.urlPadre = this.urlNonno + "/detergenza";
      let urls = [
        this.baseUrlPdf + 'LINEA GREEN.pdf'
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Linea Green'
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'hospital' && this.pdf == 'monouso') {
      this.breadcrumbs = {
        ambiente: "Hospital",
        sottoambiente: "Monouso",
        catalogo: "Igiene"
      };

      this.urlNonno = "/hospital";
      this.urlPadre = this.urlNonno + "/monouso";
      let urls = [
        this.baseUrlPdf + 'LINEA MEDICALE.pdf'
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Linea medicale'
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'cleaning' && this.pdf == 'saponi') {
      this.breadcrumbs = {
        ambiente: "Cleaning",
        sottoambiente: "Detergenza",
        catalogo: "Saponi"
      };

      this.urlNonno = "/cleaning";
      this.urlPadre = this.urlNonno + "/detergenza";
      let urls = [
        this.baseUrlPdf + 'Sapone alcol gel disinfettanti.pdf',
        this.baseUrlPdf + 'Sapone mani.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Sapone alcol gel disinfettanti',
        'Sapone mani'
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'cleaning' && this.pdf == 'lavanderia') {
      this.breadcrumbs = {
        ambiente: "Cleaning",
        sottoambiente: "Detergenza",
        catalogo: "Lavanderia"
      };

      this.urlNonno = "/cleaning";
      this.urlPadre = this.urlNonno + "/detergenza";
      let urls = [
        this.baseUrlPdf + 'Ammorbidenti.pdf',
        this.baseUrlPdf + 'Detergenti liquidi, in polvere e pastiglie.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Ammorbidenti',
        'Detergenti liquidi, in polvere e pastiglie'
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'cleaning' && this.pdf == 'panni-spugne') {
      this.breadcrumbs = {
        ambiente: "Cleaning",
        sottoambiente: "Attrezzi",
        catalogo: "Panni e Spugne"
      };

      this.urlNonno = "/cleaning";
      this.urlPadre = this.urlNonno + "/attrezzi-pulizia";
      let urls = [
        this.baseUrlPdf + 'CLEANING - PANNI E SPUGNE.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Panni e Spugne',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'cleaning' && this.pdf == 'carrelli') {
      this.breadcrumbs = {
        ambiente: "Cleaning",
        sottoambiente: "Attrezzi",
        catalogo: "Carrelli"
      };

      this.urlNonno = "/cleaning";
      this.urlPadre = this.urlNonno + "/attrezzi-pulizia";
      let urls = [
        this.baseUrlPdf + 'Carrelli con strizzatore.pdf',
        this.baseUrlPdf + 'Carrelli per sistema preimpregnato.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Carrelli con strizzatore',
        'Carrelli per sistema preimpregnato',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'cleaning' && this.pdf == 'attrezzi-accessori') {
      this.breadcrumbs = {
        ambiente: "Cleaning",
        sottoambiente: "Attrezzi",
        catalogo: "Accessori"
      };

      this.urlNonno = "/cleaning";
      this.urlPadre = this.urlNonno + "/attrezzi-pulizia";
      let urls = [
        this.baseUrlPdf + 'Manici ed aste telescopiche.pdf',
        this.baseUrlPdf + 'Sistemi lavaggio pavimento - set completi.pdf',
        this.baseUrlPdf + 'Sistemi multiuso - Mop e frange.pdf',
        this.baseUrlPdf + 'Sistemi multiuso - Telai per frange.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Manici ed aste telescopiche',
        'Sistemi lavaggio pavimento - set completi',
        'Sistemi multiuso - Mop e frange',
        'Sistemi multiuso - Telai per frange',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'cleaning' && this.pdf == 'cucina') {
      this.breadcrumbs = {
        ambiente: "Cleaning",
        sottoambiente: "Detergenza",
        catalogo: "Cucina"
      };

      this.urlNonno = "/cleaning";
      this.urlPadre = this.urlNonno + "/detergenza";
      let urls = [
        this.baseUrlPdf + 'Lavaggio manuale delle stoviglie.pdf',
        this.baseUrlPdf + 'Lavaggio meccanico delle stoviglie - Detersivi Miele Professional.pdf',
        this.baseUrlPdf + 'Lavaggio meccanico delle stoviglie.pdf',
        this.baseUrlPdf + 'Sgrassanti e detergenti superfici.pdf'
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Lavaggio manuale delle stoviglie',
        'Lavaggio meccanico delle stoviglie - Detersivi Miele Professional',
        'Lavaggio meccanico delle stoviglie',
        'Sgrassanti e detergenti superfici'
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'cleaning' && this.pdf == 'pulizie') {
      this.breadcrumbs = {
        ambiente: "Cleaning",
        sottoambiente: "Detergenza",
        catalogo: "Pulizie Generali"
      };

      this.urlNonno = "/cleaning";
      this.urlPadre = this.urlNonno + "/detergenza";
      let urls = [
        this.baseUrlPdf + 'Linea Ben Hur - per il canale Ho.Re.Ca..pdf',
        this.baseUrlPdf + 'Pulizia e cura ordinaria - Igienizzanti e disinfettanti.pdf',
        this.baseUrlPdf + 'Pulizia e cura ordinaria.pdf',
        this.baseUrlPdf + 'Pulizia pavimenti.pdf',
        this.baseUrlPdf + 'Pulizia sanitari.pdf',
        this.baseUrlPdf + 'Pulizia vetri.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Linea Ben Hur - per il canale Ho.Re.Ca.',
        'Pulizia e cura ordinaria - Igienizzanti e disinfettanti',
        'Pulizia e cura ordinaria',
        'Pulizia pavimenti',
        'Pulizia sanitari',
        'Pulizia vetri',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'hotel-bagni' && this.pdf == 'saponi') {
      this.breadcrumbs = {
        ambiente: "Hotel",
        sottoambiente: "Bagni",
        catalogo: "Saponi"
      };

      this.urlNonno = "/hotellerie";
      this.urlPadre = this.urlNonno + "/bagni";
      let urls = [
        this.baseUrlPdf + 'Sapone mani.pdf',
        this.baseUrlPdf + 'Sapone alcol gel disinfettanti.pdf'
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Mani',
        'Sapone alcol gel disinfettanti'
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'hospital-bagni' && this.pdf == 'saponi') {
      this.breadcrumbs = {
        ambiente: "Hospital",
        sottoambiente: "Bagni",
        catalogo: "Saponi"
      };

      this.urlNonno = "/hospital";
      this.urlPadre = this.urlNonno + "/bagni";
      let urls = [
        this.baseUrlPdf + 'Sapone mani.pdf',
        this.baseUrlPdf + 'Sapone alcol gel disinfettanti.pdf'
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Mani',
        'Sapone alcol gel disinfettanti'
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'hospital' && this.pdf == 'panni-scope') {
      this.breadcrumbs = {
        ambiente: "Hospital",
        sottoambiente: "Attrezzatura",
        catalogo: "Panni e scope"
      };

      this.urlNonno = "/hospital";
      this.urlPadre = this.urlNonno + "/attrezzatura";
      let urls = [
        this.baseUrlPdf + 'CLEANING - PANNI E SPUGNE.pdf',
        this.baseUrlPdf + 'Manici ed aste telescopiche.pdf',
        this.baseUrlPdf + 'Sistemi lavaggio pavimento - set completi.pdf',
        this.baseUrlPdf + 'Sistemi multiuso - Mop e frange.pdf',
        this.baseUrlPdf + 'Sistemi multiuso - Telai per frange.pdf'
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Panni e spugne',
        'Manici ed aste telescopiche',
        'Sistemi lavaggio pavimento - set completi',
        'Sistemi multiuso - Mop e frange',
        'Sistemi multiuso - Telai per frange'
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'hospital' && this.pdf == 'preimpregnato') {
      this.breadcrumbs = {
        ambiente: "Hospital",
        sottoambiente: "Attrezzatura",
        catalogo: "Sistema preimpregnato"
      };

      this.urlNonno = "/hospital";
      this.urlPadre = this.urlNonno + "/attrezzatura";
      let urls = [
        this.baseUrlPdf + 'Carrelli per sistema preimpregnato.pdf'
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Carrelli per sistema preimpregnato'
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'hospital' && this.pdf == 'carrelli') {
      this.breadcrumbs = {
        ambiente: "Hospital",
        sottoambiente: "Attrezzatura",
        catalogo: "Carrelli"
      };

      this.urlNonno = "/hospital";
      this.urlPadre = this.urlNonno + "/attrezzatura";
      let urls = [
        this.baseUrlPdf + 'Carrelli con strizzatore.pdf'
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Carrelli con strizzatore'
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'hospital' && this.pdf == 'cottura') {
      this.breadcrumbs = {
        ambiente: "Hospital",
        sottoambiente: "Cucina",
        catalogo: "Cottura"
      };

      this.urlNonno = "/hospital";
      this.urlPadre = this.urlNonno + "/cucina";
      let urls = [
        this.baseUrlPdf + '01 MKN FLEXICHEF - Apparecchio di cottura multifunzionale.pdf',
        this.baseUrlPdf + '02 MKN FLEXICOMBI - MagicPilot.pdf',
        this.baseUrlPdf + '03 SPACECOMBI - MagicPilot.pdf',
        this.baseUrlPdf + 'Microonde professionali.pdf'
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'MKN FLEXICHEF - Apparecchio di cottura multifunzionale',
        'MKN FLEXICOMBI - MagicPilot',
        'SPACECOMBI - MagicPilot',
        'Microonde professionali'
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'hospital' && this.pdf == 'detersivi') {
      this.breadcrumbs = {
        ambiente: "Hospital",
        sottoambiente: "Cucina",
        catalogo: "Detersivi"
      };

      this.urlNonno = "/hospital";
      this.urlPadre = this.urlNonno + "/cucina";
      let urls = [
        this.baseUrlPdf + 'Lavaggio manuale delle stoviglie.pdf',
        this.baseUrlPdf + 'Lavaggio meccanico delle stoviglie - Detersivi Miele Professional.pdf',
        this.baseUrlPdf + 'Lavaggio meccanico delle stoviglie.pdf',
        this.baseUrlPdf + 'Sgrassanti e detergenti superfici.pdf'
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Lavaggio manuale delle stoviglie',
        'Lavaggio meccanico delle stoviglie - Detersivi Miele Professional',
        'Lavaggio meccanico delle stoviglie',
        'Sgrassanti e detergenti superfici'
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'hospital' && this.pdf == 'disinfettanti') {
      this.breadcrumbs = {
        ambiente: "Hospital",
        sottoambiente: "Detergenza e sanificazione",
        catalogo: "Disinfettanti"
      };

      this.urlNonno = "/hospital";
      this.urlPadre = this.urlNonno + "/detergenza";
      let urls = [
        this.baseUrlPdf + 'Pulizia e cura ordinaria - Igienizzanti e disinfettanti.pdf',

      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Pulizia e cura ordinaria - Igienizzanti e disinfettanti',

      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'hospital' && this.pdf == 'san-detersivi') {
      this.breadcrumbs = {
        ambiente: "Hospital",
        sottoambiente: "Detergenza e sanificazione",
        catalogo: "Detersivi"
      };

      this.urlNonno = "/hospital";
      this.urlPadre = this.urlNonno + "/detergenza";
      let urls = [
        this.baseUrlPdf + 'Linea Ben Hur - per il canale Ho.Re.Ca..pdf',
        this.baseUrlPdf + 'Pulizia e cura ordinaria.pdf',
        this.baseUrlPdf + 'Pulizia pavimenti.pdf',
        this.baseUrlPdf + 'Pulizia sanitari.pdf',
        this.baseUrlPdf + 'Pulizia vetri.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Linea Ben Hur - per il canale Ho.Re.Ca.',
        'Pulizia e cura ordinaria',
        'Pulizia pavimenti',
        'Pulizia sanitari',
        'Pulizia vetri'
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'hospital-bagni' && this.pdf == 'distributori') {
      this.breadcrumbs = {
        ambiente: "Hospital",
        sottoambiente: "Bagni",
        catalogo: "Distributori"
      };

      this.urlNonno = "/hospital";
      this.urlPadre = this.urlNonno + "/bagni";
      let urls = [
        this.baseUrlPdf + 'Dispenser saponi.pdf'
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Dispenser saponi'
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'hotel-bagni' && this.pdf == 'distributori') {
      this.breadcrumbs = {
        ambiente: "Hotel",
        sottoambiente: "Bagni",
        catalogo: "Distributori"
      };

      this.urlNonno = "/hotellerie";
      this.urlPadre = this.urlNonno + "/bagni";
      let urls = [
        this.baseUrlPdf + 'Dispenser saponi.pdf'
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Dispenser saponi'
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'settore-alimentare' && this.pdf == 'asporto') {
      this.breadcrumbs = {
        ambiente: "Settore Alimentare",
        sottoambiente: "Asporto",
        catalogo: "Contenitori"
      };

      this.urlNonno = "/settore-alimentare";
      this.urlPadre = this.urlNonno + "/asporto";
      let urls = [
        this.baseUrlPdf + 'Contenitori e piatti in bagassa - BIOPAK.pdf',
        this.baseUrlPdf + 'Contenitori e piatti in carta e cartoncino - BIOPAK.pdf',
        this.baseUrlPdf + 'Contenitori e piatti in plastica & design.pdf',
        this.baseUrlPdf + 'Contenitori Deli in Rpet - BIOPAK.pdf',
        this.baseUrlPdf + 'Sacchetti per asporto - BIOPAK.pdf',
        this.baseUrlPdf + 'Concetto Finger Food - BIOPAK.pdf',
        this.baseUrlPdf + 'Amuse bouche.pdf',
        this.baseUrlPdf + 'Posate in legno e carta - BIOPAK.pdf',
        this.baseUrlPdf + 'DUNI 2023 BIOPAK CATALOGO IMBALLAGGIO ECO-RESPONSABILE.pdf',
        this.baseUrlPdf + 'DUNI DUNIFORM 2023 SOLUZIONI SIGILLABILI.pdf',
        this.baseUrlPdf + 'DUNI Foodservice 2023 Soluzioni di confezionamento.pdf',

      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Contenitori e piatti in bagassa - BIOPAK',
        'Contenitori e piatti in carta e cartoncino - BIOPAK',
        'Contenitori e piatti in plastica & design',
        'Contenitori Deli in Rpet - BIOPAK',
        'Sacchetti per asporto - BIOPAK',
        'Concetto Finger Food - BIOPAK',
        'Amuse bouche',
        'Posate in legno e carta - BIOPAK',
        'DUNI 2023 BIOPAK CATALOGO IMBALLAGGIO ECO-RESPONSABILE',
        'DUNI DUNIFORM 2023 SOLUZIONI SIGILLABILI',
        'DUNI Foodservice 2023 Soluzioni di confezionamento',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'ristorazione' && this.pdf == 'asporto') {
      this.breadcrumbs = {
        ambiente: "Ristorazione",
        sottoambiente: "Asporto",
        catalogo: "Contenitori"
      };

      this.urlNonno = "/ristorazione";
      this.urlPadre = this.urlNonno + "/asporto";
      let urls = [
        this.baseUrlPdf + '01 Contenitori e piatti in bagassa - biopack.pdf',
        this.baseUrlPdf + '02 Contenitori e piatti in carta e cartoncino.pdf',
        this.baseUrlPdf + '03 Contenitori e piatti in plastica & design.pdf',
        this.baseUrlPdf + '04 Contenitori Deli in Rpet.pdf',
        this.baseUrlPdf + '05 Sacchetti per asporto.pdf',
        this.baseUrlPdf + '06 Concetto Finger Food.pdf',
        this.baseUrlPdf + '07 AMUSE-BOUCHE.pdf',
        this.baseUrlPdf + '08 Posate in legno e plastica.pdf',
        this.baseUrlPdf + 'DUNI 2022 BIOPAK IMBALLAGGIO ECO-RESPONSABILE.pdf',
        this.baseUrlPdf + 'DUNI DUNIFORM 2022 SOLUZIONI SIGILLABILI.pdf',
        this.baseUrlPdf + 'DUNI Foodservice 2022 Soluzioni di confezionamento.pdf',


      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Contenitori e piatti in bagassa - biopack',
        'Contenitori e piatti in carta e cartoncino',
        'Contenitori e piatti in plastica & design',
        'Contenitori Deli in Rpet',
        'Sacchetti per asporto',
        'Concetto Finger Food',
        'Amuse bouche',
        'Posate in legno e plastica',
        'DUNI 2021 BIOPAK IMBALLAGGIO ECO-RESPONSABILE',
        'DUNI DUNIFORM 2022 SOLUZIONI SIGILLABILI',
        'DUNI Foodservice 2022 Soluzioni di confezionamento',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'risto-bagni' && this.pdf == 'distributori') {
      this.breadcrumbs = {
        ambiente: "Ristorazione",
        sottoambiente: "Bagni",
        catalogo: "Distributori"
      };

      this.urlNonno = "/ristorazione";
      this.urlPadre = this.urlNonno + "/bagni";
      let urls = [
        this.baseUrlPdf + 'Dispenser saponi.pdf'
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Dispenser saponi'
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'risto-bagni' && this.pdf == 'saponi') {
      this.breadcrumbs = {
        ambiente: "Ristorazione",
        sottoambiente: "Bagni",
        catalogo: "Saponi"
      };

      this.urlNonno = "/ristorazione";
      this.urlPadre = this.urlNonno + "/bagni";
      let urls = [
        this.baseUrlPdf + 'Sapone mani.pdf',
        this.baseUrlPdf + 'Sapone alcol gel disinfettanti.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Mani',
        'Sapone alcol gel disinfettanti'
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'settore-alimentare' && this.pdf == 'forni') {
      this.breadcrumbs = {
        ambiente: "Settore Alimentare",
        sottoambiente: "Cottura",
        catalogo: "Forni"
      };

      this.urlNonno = "/settore-alimentare";
      this.urlPadre = this.urlNonno + "/cottura";
      let urls = [
        this.baseUrlPdf + 'FOR_01 MKN FLEXICHEF - Apparecchio di cottura multifunzionale.pdf',
        this.baseUrlPdf + 'FOR_02 MKN FLEXICOMBI - MagicPilot.pdf',
        this.baseUrlPdf + 'FOR_03 SPACECOMBI - MagicPilot.pdf',
        this.baseUrlPdf + 'Microonde professionali.pdf'
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'MKN Flexichef',
        'MKN Flexicombi',
        'MKN Spacecombi',
        'Microonde professionali'
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'ristorazione' && this.pdf == 'forni') {
      this.breadcrumbs = {
        ambiente: "Ristorazione",
        sottoambiente: "Cottura",
        catalogo: "Forni"
      };

      this.urlNonno = "/ristorazione";
      this.urlPadre = this.urlNonno + "/cottura";
      let urls = [
        this.baseUrlPdf + 'FOR_01 MKN FLEXICHEF - Apparecchio di cottura multifunzionale.pdf',
        this.baseUrlPdf + 'FOR_02 MKN FLEXICOMBI - MagicPilot.pdf',
        this.baseUrlPdf + 'FOR_03 SPACECOMBI - MagicPilot.pdf',
        this.baseUrlPdf + 'Microonde professionali.pdf',
        this.baseUrlPdf + 'Aspiratore speciale per forni.pdf',
        this.baseUrlPdf + 'Forni per pizza.pdf',

      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'MKN Flexichef',
        'MKN Flexicombi',
        'MKN Spacecombi',
        'Microonde professionali',
        'Aspiratore speciale per forni',
        'Forni per pizza',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'ristorazione' && this.pdf == 'abbattitori') {
      this.breadcrumbs = {
        ambiente: "Ristorazione",
        sottoambiente: "Freddo",
        catalogo: "Abbattitori"
      };

      this.urlNonno = "/ristorazione";
      this.urlPadre = this.urlNonno + "/freddo";
      let urls = [
        this.baseUrlPdf + 'Abbattitori Modi.pdf',
        this.baseUrlPdf + 'Abbattitori VISION.pdf',
        this.baseUrlPdf + 'Armadi e Tavoli Fermalievitazione.pdf',
        this.baseUrlPdf + 'DALMEC Abbattitore ALASKA.pdf',
        this.baseUrlPdf + 'DALMEC Fermalievita 700-900.pdf',

      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'Abbattitori Modi',
        'Abbattitori VISION',
        'Armadi e Tavoli Fermalievitazione',
        'DALMEC Abbattitore ALASKA',
        'DALMEC Fermalievita 700-900',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'ristorazione' && this.pdf == 'frigoriferi') {
      this.breadcrumbs = {
        ambiente: "Ristorazione",
        sottoambiente: "Freddo",
        catalogo: "Frigoriferi"
      };

      this.urlNonno = "/ristorazione";
      this.urlPadre = this.urlNonno + "/freddo";
      let urls = [
        this.baseUrlPdf + 'Armadi MASTER e SMART.pdf',
        this.baseUrlPdf + 'Tavoli pizza.pdf',
        this.baseUrlPdf + 'Tavoli refrigerati.pdf',
        this.baseUrlPdf + 'DALMEC Frigorifero Pasticceria.pdf',

      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'Armadi MASTER e SMART',
        'Tavoli pizza',
        'Tavoli refrigerati',
        'DALMEC Frigorifero Pasticceria',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'cleaning' && this.pdf == 'deo-ambienti') {
      this.breadcrumbs = {
        ambiente: "Cleaning",
        sottoambiente: "Deodoranti",
        catalogo: "Ambienti"
      };

      this.urlNonno = "/cleaning";
      this.urlPadre = this.urlNonno + "/deodoranti";
      let urls = [
        this.baseUrlPdf + '01 Profumi, suggerimenti.pdf',
        this.baseUrlPdf + '02 Diffusori Basic a batteria.pdf',
        this.baseUrlPdf + '03 Diffusori senza batteria.pdf',
        this.baseUrlPdf + '04 Deodoranti manuali.pdf',

      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'Profumi, suggerimenti',
        'Diffusori Basic a batteria',
        'Diffusori senza batteria',
        'Deodoranti manuali',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'cleaning' && this.pdf == 'insetticidi') {
      this.breadcrumbs = {
        ambiente: "Cleaning",
        sottoambiente: "Deodoranti",
        catalogo: "Insetticidi"
      };

      this.urlNonno = "/cleaning";
      this.urlPadre = this.urlNonno + "/deodoranti";
      let urls = [
        this.baseUrlPdf + 'Insetticidi al piretro naturale.pdf',

      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'Insetticidi al piretro naturale',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'cleaning' && this.pdf == 'deo-disinfettanti') {
      this.breadcrumbs = {
        ambiente: "Cleaning",
        sottoambiente: "Deodoranti",
        catalogo: "Disinfettanti"
      };

      this.urlNonno = "/cleaning";
      this.urlPadre = this.urlNonno + "/deodoranti";
      let urls = [
        this.baseUrlPdf + 'Disinfettanti spray e pavimenti.pdf',

      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'Disinfettanti spray e pavimenti',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'hotel' && this.pdf == 'complementi') {
      this.breadcrumbs = {
        ambiente: "Hotel",
        sottoambiente: "Camera",
        catalogo: "Complementi"
      };

      this.urlNonno = "/hotellerie";
      this.urlPadre = this.urlNonno + "/camera";
      let urls = [
        this.baseUrlPdf + 'Frigo Mini-Bar.pdf',
        this.baseUrlPdf + 'LINEA CAMERA - Complementari.pdf',

      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'Frigo Mini-Bar',
        'LINEA CAMERA - Complementari',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'alim' && this.pdf == 'frigoriferi') {
      this.breadcrumbs = {
        ambiente: "Settore Alimentare",
        sottoambiente: "Freddo",
        catalogo: "Frigoriferi"
      };

      this.urlNonno = "/settore-alimentare";
      this.urlPadre = this.urlNonno + "/freddo";
      let urls = [
        this.baseUrlPdf + 'Armadi MASTER e SMART.pdf',
        this.baseUrlPdf + 'Tavoli pizza.pdf',
        this.baseUrlPdf + 'Tavoli refrigerati.pdf',

      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'Armadi MASTER e SMART',
        'Tavoli pizza',
        'Tavoli refrigerati',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'alim' && this.pdf == 'abbattitori') {
      this.breadcrumbs = {
        ambiente: "Settore Alimentare",
        sottoambiente: "Freddo",
        catalogo: "Abbattitori"
      };

      this.urlNonno = "/settore-alimentare";
      this.urlPadre = this.urlNonno + "/freddo";
      let urls = [
        this.baseUrlPdf + 'Abbattitori Modi.pdf',
        this.baseUrlPdf + 'Abbattitori VISION.pdf',
        this.baseUrlPdf + 'Armadi e Tavoli Fermalievitazione.pdf',

      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'Abbattitori Modi',
        'Abbattitori VISION',
        'Armadi e Tavoli Fermalievitazione',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }

    else if (this.path == 'hospital' && this.pdf == 'cucina-lavaggio') {
      this.breadcrumbs = {
        ambiente: "Hospital",
        sottoambiente: "Cucina",
        catalogo: "Lavaggio"
      };

      this.urlNonno = "/hospital";
      this.urlPadre = this.urlNonno + "/cucina";
      let urls = [
        this.baseUrlPdf + 'Lavabicchieri industriali.pdf',
        this.baseUrlPdf + 'Lavastoviglie frontali.pdf',
        this.baseUrlPdf + 'Asciugaposate.pdf',
        this.baseUrlPdf + 'Lavaposate-lavabicchieri PG 8058 U.pdf',
        this.baseUrlPdf + 'Lavastoviglie a capottina.pdf',
        this.baseUrlPdf + 'Lavaoggetti.pdf',
        this.baseUrlPdf + 'Lavastoviglie a traino.pdf',
        this.baseUrlPdf + 'Rubinetteria industriale.pdf',
        this.baseUrlPdf + 'NEW Lavabicchierie Lavastoviglie AX - UX.pdf',

      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'Lavabicchieri industriali',
        'Lavastoviglie frontali',
        'Asciugaposate',
        'Lavaposate-lavabicchieri PG 8058 U',
        'Lavastoviglie a capottina',
        'Lavaoggetti',
        'Lavastoviglie a traino',
        'Rubinetteria industriale',
        'Lavabicchierie Lavastoviglie AX - UX',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }

    else if (this.path == 'ristorazione' && this.pdf == 'cucina') {
      this.breadcrumbs = {
        ambiente: "Ristorazione",
        sottoambiente: "Cottura",
        catalogo: "Cucina"
      };

      this.urlNonno = "/ristorazione";
      this.urlPadre = this.urlNonno + "/cottura";
      let urls = [
        this.baseUrlPdf + 'CUC_01 MENU SYSTEM  Apparecchi per la cottura a induzione.pdf',
        this.baseUrlPdf + 'CUC_02 MENU SYSTEM Cucine ad induzione_ Informazioni e vantaggi.pdf',
        this.baseUrlPdf + 'CUC_03 MENU SYSTEM Apparecchi Portatili SINA.pdf',
        this.baseUrlPdf + 'Piastre caffetteria.pdf',
        this.baseUrlPdf + 'EnergyControl Ottimizzazione Energetica.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Menu System - Apparecchi per cottura ad induzione',
        'Menu System - Cucine ad induzione: dettagli',
        'Menu System - Apparecchi portatili SINA',
        'Piastre caffetteria',
        'EnergyControl Ottimizzazione Energetica',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'settore-alimentare' && this.pdf == 'cucina') {
      this.breadcrumbs = {
        ambiente: "Settore Alimentare",
        sottoambiente: "Cottura",
        catalogo: "Cucina"
      };

      this.urlNonno = "/settore-alimentare";
      this.urlPadre = this.urlNonno + "/cottura";
      let urls = [
        this.baseUrlPdf + 'CUC_01 MENU SYSTEM  Apparecchi per la cottura a induzione.pdf',
        this.baseUrlPdf + 'CUC_02 MENU SYSTEM Cucine ad induzione_ Informazioni e vantaggi.pdf',
        this.baseUrlPdf + 'CUC_03 MENU SYSTEM Apparecchi Portatili SINA.pdf',
        this.baseUrlPdf + 'Piastre caffetteria.pdf',
        this.baseUrlPdf + 'EnergyControl Ottimizzazione Energetica.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Menu System - Apparecchi per cottura ad induzione',
        'Menu System - Cucine ad induzione: dettagli',
        'Menu System - Apparecchi portatili SINA',
        'Piastre caffetteria',
        'EnergyControl Ottimizzazione Energetica',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'hotellerie' && this.pdf == 'carrelli') {
      this.breadcrumbs = {
        ambiente: "Hotel",
        sottoambiente: "Camera",
        catalogo: "Carrelli"
      };

      this.urlNonno = "/hotellerie";
      this.urlPadre = this.urlNonno + "/camera";
      let urls = [
        this.baseUrlPdf + 'HOTEL_CAMERA_Carrelli servizio ai piani.pdf',
        this.baseUrlPdf + 'HOTEL_CAMERA_Carrelli portavaligie - servizio in camera.pdf',
        this.baseUrlPdf + 'HOTEL_CAMERA_Carrelli biancheria sporca.pdf'
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Servizio ai piani',
        'Portastoviglie - Servizio in camera',
        'Biancheria sporca'
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'ristorazione' && this.pdf == 'basse-temperature') {
      this.breadcrumbs = {
        ambiente: "Ristorazione",
        sottoambiente: "Cottura",
        catalogo: "Basse temperature"
      };

      this.urlNonno = "/ristorazione";
      this.urlPadre = this.urlNonno + "/cottura";
      let urls = [
        this.baseUrlPdf + 'Cuocitori sottovuoto SOUS-VIDE.pdf',
        this.baseUrlPdf + 'Sottovuoto gamma Sensor ULTRA.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',

      ];
      let names = [
        'Cuocitori sottovuoto SOUS-VIDE',
        'Sottovuoto gamma Sensor ULTRA',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'conservazione' && this.pdf == 'contenitori') {
      this.breadcrumbs = {
        ambiente: "Settore Alimentare",
        sottoambiente: "Conservazione e Stoccaggio",
        catalogo: "Contenitori"
      };

      this.urlNonno = "/settore-alimentare";
      this.urlPadre = this.urlNonno + "/conservazione-stoccaggio";
      let urls = [
        this.baseUrlPdf + 'Conservazione WEK.pdf'
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Conservazione WEK'
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'conservazione' && this.pdf == 'stoccaggio') {
      this.breadcrumbs = {
        ambiente: "Settore Alimentare",
        sottoambiente: "Conservazione e Stoccaggio",
        catalogo: "Stoccaggio"
      };

      this.urlNonno = "/settore-alimentare";
      this.urlPadre = this.urlNonno + "/conservazione-stoccaggio";
      let urls = [
        this.baseUrlPdf + 'Scaffali per stoccaggio.pdf',
        this.baseUrlPdf + 'Trasporto e stoccaggio.pdf'
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Scaffali per stoccaggio',
        'Trasporto e stoccaggio'
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'conservazione' && this.pdf == 'macchine') {
      this.breadcrumbs = {
        ambiente: "Settore Alimentare",
        sottoambiente: "Conservazione e Stoccaggio",
        catalogo: "Macchine"
      };

      this.urlNonno = "/settore-alimentare";
      this.urlPadre = this.urlNonno + "/conservazione-stoccaggio";
      let urls = [
        this.baseUrlPdf + 'Confezionatrici sottovuoto Gamma EXT.pdf',
        this.baseUrlPdf + 'Confezionatrici sottovuoto Gamma SENSOR.pdf',
        this.baseUrlPdf + 'Confezionatrici sottovuoto Sensor ULTRA.pdf',
        this.baseUrlPdf + 'Termosaldatrici Manuali.pdf'
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Confezionatrici sottovuoto Gamma EXT',
        'Confezionatrici sottovuoto Gamma Sensor',
        'Confezionatrici sottovuoto Sensor Ultra',
        'Termosaldatrici Manuali'
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'ristorazione' && this.pdf == 'neutro-inox') {
      this.breadcrumbs = {
        ambiente: "Ristorazione",
        sottoambiente: "Lavaggio",
        catalogo: "Neutro inox"
      };

      this.urlNonno = "/ristorazione";
      this.urlPadre = this.urlNonno + "/lavaggio";
      let urls = [
        this.baseUrlPdf + 'LA PROGETTAZIONE.pdf'
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'La progettazione'
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'alimentare' && this.pdf == 'neutro-inox') {
      this.breadcrumbs = {
        ambiente: "Settore alimentare",
        sottoambiente: "Lavaggio",
        catalogo: "Neutro inox"
      };

      this.urlNonno = "/settore-alimentare";
      this.urlPadre = this.urlNonno + "/lavaggio";
      let urls = [
        this.baseUrlPdf + 'LA PROGETTAZIONE.pdf'
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'La progettazione'
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'alim-lavaggio' && this.pdf == 'lavastoviglie') {
      this.breadcrumbs = {
        ambiente: "Settore Alimentare",
        sottoambiente: "Lavaggio",
        catalogo: "Lavastoviglie"
      };

      this.urlNonno = "/settore-alimentare";
      this.urlPadre = this.urlNonno + "/lavaggio";
      let urls = [
        this.baseUrlPdf + 'Lavastoviglie frontali.pdf',
        this.baseUrlPdf + 'Lavastoviglie a capottina.pdf',
        this.baseUrlPdf + 'Lavaoggetti.pdf',
        this.baseUrlPdf + 'Lavastoviglie a traino.pdf',
        this.baseUrlPdf + 'Rubinetteria industriale.pdf',
        this.baseUrlPdf + 'EnergyControl Ottimizzazione Energetica.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Lavastoviglie frontali',
        'Lavastoviglie a capottina',
        'Lavaoggetti',
        'Lavastoviglie a traino',
        'Rubinetteria industriale',
        'EnergyControl Ottimizzazione Energetica',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'risto-lavaggio' && this.pdf == 'lavastoviglie') {
      this.breadcrumbs = {
        ambiente: "Ristorazione",
        sottoambiente: "Lavaggio",
        catalogo: "Lavastoviglie"
      };

      this.urlNonno = "/ristorazione";
      this.urlPadre = this.urlNonno + "/lavaggio";
      let urls = [
        this.baseUrlPdf + 'Lavastoviglie frontali.pdf',
        this.baseUrlPdf + 'Lavastoviglie a capottina.pdf',
        this.baseUrlPdf + 'Lavaoggetti.pdf',
        this.baseUrlPdf + 'Lavastoviglie a traino.pdf',
        this.baseUrlPdf + 'Rubinetteria industriale.pdf',
        this.baseUrlPdf + 'EnergyControl Ottimizzazione Energetica.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Lavastoviglie frontali',
        'Lavastoviglie a capottina',
        'Lavaoggetti',
        'Lavastoviglie a traino',
        'Rubinetteria industriale',
        'EnergyControl Ottimizzazione Energetica',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'risto-lavaggio' && this.pdf == 'lavaposate') {
      this.breadcrumbs = {
        ambiente: "Ristorazione",
        sottoambiente: "Lavaggio",
        catalogo: "Lavaposate"
      };

      this.urlNonno = "/ristorazione";
      this.urlPadre = this.urlNonno + "/lavaggio";
      let urls = [
        this.baseUrlPdf + 'Asciugaposate.pdf',
        this.baseUrlPdf + 'Lavaposate-lavabicchieri PG 8058 U.pdf',
        this.baseUrlPdf + 'NEW Lavabicchierie Lavastoviglie AX - UX.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Asciugaposate',
        'Lavaposate - Lavabicchieri PG 8058 U',
        'Lavabicchierie Lavastoviglie AX - UX',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'alim-lavaggio' && this.pdf == 'lavaposate') {
      this.breadcrumbs = {
        ambiente: "Settore Alimentare",
        sottoambiente: "Lavaggio",
        catalogo: "Lavaposate"
      };

      this.urlNonno = "/settore-alimentare";
      this.urlPadre = this.urlNonno + "/lavaggio";
      let urls = [
        this.baseUrlPdf + 'Asciugaposate.pdf',
        this.baseUrlPdf + 'Lavaposate-lavabicchieri PG 8058 U.pdf'
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Asciugaposate',
        'Lavaposate - Lavabicchieri PG 8058 U'
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'alim-lavaggio' && this.pdf == 'lavabicchieri') {
      this.breadcrumbs = {
        ambiente: "Settore Alimentare",
        sottoambiente: "Lavaggio",
        catalogo: "Lavabicchieri"
      };

      this.urlNonno = "/settore-alimentare";
      this.urlPadre = this.urlNonno + "/lavaggio";
      let urls = [
        this.baseUrlPdf + 'Lavastoviglie frontali.pdf',
        this.baseUrlPdf + 'NEW Lavabicchierie Lavastoviglie AX - UX.pdf',
        this.baseUrlPdf + 'Lavabicchieri industriali.pdf',

      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Lavastoviglie frontali',
        'Lavabicchierie Lavastoviglie AX - UX',
        'Lavabicchieri industriale',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'ristorazione' && this.pdf == 'macchine-speciali') {
      this.breadcrumbs = {
        ambiente: "Ristorazione",
        sottoambiente: "Cottura",
        catalogo: "Macchine Speciali"
      };


      this.video = true;
      this.urlVideo = [
        'https://youtu.be/awhJqSqHXZY'
      ]
      this.urlNonno = "/ristorazione";
      this.urlPadre = this.urlNonno + "/cottura";
      let urls = [
        this.baseUrlPdf + '01 Catalogo Completo HotmixPRO.pdf',
        this.baseUrlPdf + '02 Brochure Hotmix Creative.pdf',
        this.baseUrlPdf + '03 Brochure Hotmix Affumicatore.pdf',
        this.baseUrlPdf + '04 Brochure Hotmix Disidratatore.pdf',
        this.baseUrlPdf + '05 Brochure HotmixPRO Freddo_ Giaz e Crio.pdf',
        this.baseUrlPdf + '06 VITO_Sistema-filtraggio-olio.pdf',
        this.baseUrlPdf + '07 VITO_Istruzioni Sintetiche.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Catalogo Completo HotmixPRO',
        'Brochure Hotmix Creative',
        'Brochure Hotmix Affumicatore',
        'Brochure Hotmix Disidratatore',
        'Brochure HotmixPRO Freddo Giaz e Crio',
        'VITO Sistema filtraggio olio',
        'VITO Istruzioni Sintetiche',
      ];

      for (let j = 0; j < this.urlVideo.length; j++) {

        this.safeUrl[j] = this._sanitizer.sanitize(SecurityContext.RESOURCE_URL, this._sanitizer.bypassSecurityTrustResourceUrl(this.urlVideo[j]));
        // this.safeUrl[j] = this._sanitizer.bypassSecurityTrustResourceUrl(this.urlVideo[j]);
        // this.safeUrl[j] = this.safeUrl[j].changingThisBreaksApplicationSecurity;
        this.finalSurl[j] = this.safeUrl[j];
      }
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'settore-alimentare' && this.pdf == 'macchine-speciali') {
      this.breadcrumbs = {
        ambiente: "Settore Alimentare",
        sottoambiente: "Cottura",
        catalogo: "Macchine Speciali"
      };

      this.urlNonno = "/settore-alimentare";
      this.urlPadre = this.urlNonno + "/cottura";
      let urls = [
        this.baseUrlPdf + '01 Catalogo Completo HotmixPRO.pdf',
        this.baseUrlPdf + '02 Brochure Hotmix Creative.pdf',
        this.baseUrlPdf + '03 Brochure Hotmix Affumicatore.pdf',
        this.baseUrlPdf + '04 Brochure Hotmix Disidratatore.pdf',
        this.baseUrlPdf + '05 Brochure HotmixPRO Freddo_ Giaz e Crio.pdf',
        this.baseUrlPdf + '06 VITO_Sistema-filtraggio-olio.pdf',
        this.baseUrlPdf + '07 VITO_Istruzioni Sintetiche.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Catalogo Completo HotmixPRO',
        'Brochure Hotmix Creative',
        'Brochure Hotmix Affumicatore',
        'Brochure Hotmix Disidratatore',
        'Brochure HotmixPRO Freddo Giaz e Crio',
        'VITO Sistema filtraggio olio',
        'VITO Istruzioni Sintetiche',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'settore-alimentare' && this.pdf == 'affettatrici') {
      this.breadcrumbs = {
        ambiente: "Settore Alimentare",
        sottoambiente: "Preparazione Dinamica",
        catalogo: "Affettatrici"
      };

      this.urlNonno = "/settore-alimentare";
      this.urlPadre = this.urlNonno + "/preparazione-dinamica";
      let urls = [
        this.baseUrlPdf + 'Affettatrici.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'Affettatrici',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'settore-alimentare' && this.pdf == 'tritacarne') {
      this.breadcrumbs = {
        ambiente: "Settore Alimentare",
        sottoambiente: "Preparazione Dinamica",
        catalogo: "Tritacarne"
      };

      this.urlNonno = "/settore-alimentare";
      this.urlPadre = this.urlNonno + "/preparazione-dinamica";
      let urls = [
        this.baseUrlPdf + 'Tritacarne, grattugie.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'Tritacarne, grattugie',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'settore-alimentare' && this.pdf == 'trituratori-sbattitori') {
      this.breadcrumbs = {
        ambiente: "Settore Alimentare",
        sottoambiente: "Preparazione Dinamica",
        catalogo: "Trituratori e Sbattitori"
      };

      this.urlNonno = "/settore-alimentare";
      this.urlPadre = this.urlNonno + "/preparazione-dinamica";
      let urls = [
        this.baseUrlPdf + 'Trituratori e sbattitori.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'Trituratori e sbattitori',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'settore-alimentare' && this.pdf == 'impastatrici') {
      this.breadcrumbs = {
        ambiente: "Settore Alimentare",
        sottoambiente: "Preparazione Dinamica",
        catalogo: "Impastatrici"
      };

      this.urlNonno = "/settore-alimentare";
      this.urlPadre = this.urlNonno + "/preparazione-dinamica";
      let urls = [
        this.baseUrlPdf + 'Impastatrici planetarie.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'Impastatrici planetarie',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'settore-alimentare' && this.pdf == 'bilance') {
      this.breadcrumbs = {
        ambiente: "Settore Alimentare",
        sottoambiente: "Preparazione Dinamica",
        catalogo: "Bilance"
      };

      this.urlNonno = "/settore-alimentare";
      this.urlPadre = this.urlNonno + "/preparazione-dinamica";
      let urls = [
        this.baseUrlPdf + 'Bilance professionali.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'Bilance professionali',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'settore-alimentare' && this.pdf == 'cutter') {
      this.breadcrumbs = {
        ambiente: "Settore Alimentare",
        sottoambiente: "Preparazione Dinamica",
        catalogo: "Cutter"
      };

      this.urlNonno = "/settore-alimentare";
      this.urlPadre = this.urlNonno + "/preparazione-dinamica";
      let urls = [
        this.baseUrlPdf + 'Cutter, Omogeneizzatori.pdf',
        this.baseUrlPdf + 'Pelapatate.pdf',
        this.baseUrlPdf + 'Scolainsalata, Centifughe.pdf',
        this.baseUrlPdf + 'Tagliaverdure.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'Cutter, omogeinizzatori',
        'Pelapatate',
        'Scolainsalata, Centrifughe',
        'Tagliaverdure',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'risto-lavaggio' && this.pdf == 'lavabicchieri') {
      this.breadcrumbs = {
        ambiente: "Ristorazione",
        sottoambiente: "Lavaggio",
        catalogo: "Lavabicchieri"
      };

      this.urlNonno = "/ristorazione";
      this.urlPadre = this.urlNonno + "/lavaggio";
      let urls = [
        // this.baseUrlPdf + '01 Lavabicchieri.pdf',
        this.baseUrlPdf + 'Lavastoviglie frontali.pdf',
        this.baseUrlPdf + 'NEW Lavabicchierie Lavastoviglie AX - UX.pdf',
        this.baseUrlPdf + 'Lavabicchieri industriali.pdf',
      ];
      let imgs = [
        // this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        // 'Lavabicchieri',
        'Lavastoviglie frontali',
        'Lavabicchierie Lavastoviglie AX - UX',
        'Lavabicchieri industriale',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'ristorazione-freddo' && this.pdf == 'macchine-speciali') {
      this.breadcrumbs = {
        ambiente: "Ristorazione",
        sottoambiente: "Freddo",
        catalogo: "Macchine Speciali"
      };

      this.urlNonno = "/ristorazione";
      this.urlPadre = this.urlNonno + "/freddo";
      let urls = [
        this.baseUrlPdf + 'Abbattitori, fermalievitazione e conservatori VISION.pdf',
        this.baseUrlPdf + 'Cantinette per vini.pdf',
        this.baseUrlPdf + 'KLIMA MEAT Maturatore di carni.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'Abbattitori, fermalievitazione e conservatori VISION',
        'Cantinette per vini',
        'KLIMA MEAT Maturatore di carni',

      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'cleaning' && this.pdf == 'igienizzazione') {
      this.breadcrumbs = {
        ambiente: "Cleaning",
        sottoambiente: "Sanificazione",
        catalogo: "Igienizzazione"
      };

      this.urlNonno = "/cleaning";
      this.urlPadre = this.urlNonno + "/sanificazione";
      let urls = [
        this.baseUrlPdf + 'Pulizia e cura ordinaria - Igienizzanti e disinfettanti.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'Pulizia e cura ordinaria - Igienizzanti e disinfettanti',


      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'cleaning' && this.pdf == 'macchine') {
      this.breadcrumbs = {
        ambiente: "Cleaning",
        sottoambiente: "Sanificazione",
        catalogo: "Macchine"
      };

      this.urlNonno = "/cleaning";
      this.urlPadre = this.urlNonno + "/sanificazione";
      let urls = [
        this.baseUrlPdf + 'TERMO MACHINE -Termo nebulizzatrice.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'TERMO MACHINE -Termo nebulizzatrice',


      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'alim-freddo' && this.pdf == 'macchine-speciali') {
      this.breadcrumbs = {
        ambiente: "Settore Alimentare",
        sottoambiente: "Freddo",
        catalogo: "Macchine Speciali"
      };

      this.urlNonno = "/settore-alimentare";
      this.urlPadre = this.urlNonno + "/freddo";
      let urls = [
        this.baseUrlPdf + 'Abbattitori, fermalievitazione e conservatori VISION.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'Abbattitori, fermalievitazione e conservatori VISION',

      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'ristorazione' && this.pdf == 'fabbricatori-ghiaccio') {
      this.breadcrumbs = {
        ambiente: "Ristorazione",
        sottoambiente: "Freddo",
        catalogo: "Fabbricatori ghiaccio"
      };

      this.urlNonno = "/ristorazione";
      this.urlPadre = this.urlNonno + "/freddo";
      let urls = [
        this.baseUrlPdf + 'Fabbricatori Ghiaccio pieno.pdf',
        this.baseUrlPdf + 'Fabbricatori Ghiaccio vuoto.pdf',
        this.baseUrlPdf + 'Ghiaccio triturato.pdf'
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Fabbricatori ghiaccio pieno',
        'Fabbricatori ghiaccio vuoto',
        'Ghiaccio triturato'
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'alim' && this.pdf == 'fabbricatori-ghiaccio') {
      this.breadcrumbs = {
        ambiente: "Settore Alimentare",
        sottoambiente: "Freddo",
        catalogo: "Fabbricatori ghiaccio"
      };

      this.urlNonno = "/settore-alimentare";
      this.urlPadre = this.urlNonno + "/freddo";
      let urls = [
        this.baseUrlPdf + 'Fabbricatori ghiaccio pieno.pdf',
        this.baseUrlPdf + 'Fabbricatori ghiaccio vuoto.pdf',
        this.baseUrlPdf + 'Ghiaccio triturato.pdf'
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Fabbricatori ghiaccio pieno',
        'Fabbricatori ghiaccio vuoto',
        'Ghiaccio triturato'
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'hotel' && this.pdf == 'caffetteria') {
      this.breadcrumbs = {
        ambiente: "Hotel",
        sottoambiente: "Colazione",
        catalogo: "Macchine caffè/cappuccino"
      };

      this.urlNonno = "/hotellerie";
      this.urlPadre = this.urlNonno + "/colazione";
      let urls = [
        this.baseUrlPdf + 'BRAVILOR_Bolero  Macchina per solubili.pdf',
        this.baseUrlPdf + 'BRAVILOR_ESPRECIOUS Macchina automatica caffe.pdf',
        this.baseUrlPdf + 'BRAVILOR_Sego Macchina caffè espresso.pdf'
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'BRAVILOR Bolero Macchina per solubili',
        'BRAVILOR ESPRECIOUS Macchina automatica caffe',
        'BRAVILOR Sego Macchina Espresso'
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'hotel' && this.pdf == 'detersivi') {
      this.breadcrumbs = {
        ambiente: "Hotel",
        sottoambiente: "Lavanderia",
        catalogo: "Detersivi"
      };

      this.urlNonno = "/hotellerie";
      this.urlPadre = this.urlNonno + "/lavanderia";
      let urls = [
        this.baseUrlPdf + 'Ammorbidenti.pdf',
        this.baseUrlPdf + 'Detergenti liquidi, in polvere e pastiglie.pdf',
        this.baseUrlPdf + 'EnergyControl Ottimizzazione Energetica.pdf',
        this.baseUrlPdf + 'Sistemi dosaggio lavanderia.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'Ammorbidenti',
        'Detergenti liquidi, in polvere e pastiglie',
        'EnergyControl Ottimizzazione Energetica',
        'Sistemi dosaggio lavanderia',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'hospital-lava' && this.pdf == 'detersivi') {
      this.breadcrumbs = {
        ambiente: "Hospital",
        sottoambiente: "Lavanderia",
        catalogo: "Detersivi"
      };

      this.urlNonno = "/hospital";
      this.urlPadre = this.urlNonno + "/lavanderia";
      let urls = [
        this.baseUrlPdf + 'Ammorbidenti.pdf',
        this.baseUrlPdf + 'Detergenti liquidi, in polvere e pastiglie.pdf',
        this.baseUrlPdf + 'EnergyControl Ottimizzazione Energetica.pdf',
        this.baseUrlPdf + 'Sistemi dosaggio lavanderia.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'Ammorbidenti',
        'Detergenti liquidi, in polvere e pastiglie',
        'EnergyControl Ottimizzazione Energetica',
        'Sistemi dosaggio lavanderia',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'hotellerie' && this.pdf == 'macchinari') {
      this.breadcrumbs = {
        ambiente: "Hotel",
        sottoambiente: "Pulizia",
        catalogo: "Macchinari"
      };

      this.urlNonno = "/hotellerie";
      this.urlPadre = this.urlNonno + "/pulizia";
      let urls = [
        this.baseUrlPdf + 'Macchinari per la pulizia.pdf'
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'Macchine per pulizia',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'ristorazione' && this.pdf == 'coltelleria') {
      this.breadcrumbs = {
        ambiente: "Ristorazione",
        sottoambiente: "Cottura",
        catalogo: "Coltelleria"
      };

      this.urlNonno = "/ristorazione";
      this.urlPadre = this.urlNonno + "/cottura";
      let urls = [
        this.baseUrlPdf + '01 Collezione Wusthof.pdf',
        this.baseUrlPdf + '02 Coltelli, spatole e accessori.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'Collezione Wusthof',
        'Coltelli, spatole e accessori',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'alim' && this.pdf == 'coltelleria') {
      this.breadcrumbs = {
        ambiente: "Settore Alimentare",
        sottoambiente: "Cottura",
        catalogo: "Coltelleria"
      };

      this.urlNonno = "/settore-alimentare";
      this.urlPadre = this.urlNonno + "/cottura";
      let urls = [
        this.baseUrlPdf + '01 Collezione Wusthof.pdf',
        this.baseUrlPdf + '02 Coltelli, spatole e accessori.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'Collezione Wusthof',
        'Coltelli, spatole e accessori',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'ristorazione' && this.pdf == 'confezionamento') {
      this.breadcrumbs = {
        ambiente: "Ristorazione",
        sottoambiente: "Asporto",
        catalogo: "Confezionamento"
      };

      this.urlNonno = "/ristorazione";
      this.urlPadre = this.urlNonno + "/asporto";
      let urls = [
        this.baseUrlPdf + 'Termosigillatrice di vaschette con pellicola.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'Termosigillatrice di vaschette con pellicola',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'alim' && this.pdf == 'confezionamento') {
      this.breadcrumbs = {
        ambiente: "Settore Alimentare",
        sottoambiente: "Asporto",
        catalogo: "Confezionamento"
      };

      this.urlNonno = "/settore-alimentare";
      this.urlPadre = this.urlNonno + "/asporto";
      let urls = [
        this.baseUrlPdf + 'Termosigillatrice di vaschette con pellicola.pdf',
        this.baseUrlPdf + 'DUNI DUNIFORM 2022 SOLUZIONI SIGILLABILI.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'Termosigillatrice di vaschette con pellicola',
        'Duniform Soluzioni sigillabili',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'alim' && this.pdf == 'trasporto') {
      this.breadcrumbs = {
        ambiente: "Settore Alimentare",
        sottoambiente: "Asporto",
        catalogo: "Trasporto"
      };

      this.urlNonno = "/settore-alimentare";
      this.urlPadre = this.urlNonno + "/asporto";
      let urls = [
        this.baseUrlPdf + 'Contenitori isotermici Polipropilene.pdf',
        this.baseUrlPdf + 'TERMOTRANS - Contenitori isotermici.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'Contenitori isotermici Polipropilene',
        'TERMOTRANS - Contenitori isotermici',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'dehors' && this.pdf == 'strutture') {
      this.breadcrumbs = {
        ambiente: "Outdoor",
        sottoambiente: "Dehors",
        catalogo: "Strutture"
      };

      this.urlNonno = "/outdoor";
      this.urlPadre = this.urlNonno + "";
      let urls = [
        this.baseUrlPdf + 'STAR PROGETTI Paraventi e strutture modulari per dehors.pdf',
        this.baseUrlPdf + 'ADAMI Strutture e gazebo per dehors.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'STAR PROGETTI Paraventi e strutture modulari per dehors',
        'ADAMI Strutture e gazebo per dehors',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'dehors' && this.pdf == 'coperture') {
      this.breadcrumbs = {
        ambiente: "Outdoor",
        sottoambiente: "Dehors",
        catalogo: "Ombrelloni e coperture"
      };

      this.urlNonno = "/outdoor";
      this.urlPadre = this.urlNonno + "";
      let urls = [
        this.baseUrlPdf + '2 GROSFILLEX Ombrelloni.pdf',
        this.baseUrlPdf + 'POGGESI_OMBRELLONI.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'GROSFILLEX Ombrelloni',
        'POGGESI Ombrelloni',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'dehors' && this.pdf == 'sedute-tavoli') {
      this.breadcrumbs = {
        ambiente: "Outdoor",
        sottoambiente: "Dehors",
        catalogo: "Sedute e tavoli"
      };

      this.urlNonno = "/outdoor";
      this.urlPadre = this.urlNonno + "";
      let urls = [
        this.baseUrlPdf + '3 BELAIR Tavoli e sedie parte 1.pdf',
        this.baseUrlPdf + '3 BELAIR Tavoli e sedie parte 2.pdf',
        this.baseUrlPdf + '3 GROSFILLEX Sedie.pdf',
        this.baseUrlPdf + '3 GROSFILLEX Sedute.pdf',
        this.baseUrlPdf + '3 GROSFILLEX Tavoli.pdf',
        this.baseUrlPdf + 'VERMOBIL  Tavoli e sedute.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'BELAIR Tavoli e sedie parte 1',
        'BELAIR Tavoli e sedie parte 2',
        'GROSFILLEX Sedie',
        'GROSFILLEX Sedute',
        'GROSFILLEX Tavoli',
        'VERMOBIL  Tavoli e sedute',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'ristorazione' && this.pdf == 'pentolame') {
      this.breadcrumbs = {
        ambiente: "Ristorazione",
        sottoambiente: "Cottura",
        catalogo: "Pentolame"
      };

      this.urlNonno = "/ristorazione";
      this.urlPadre = this.urlNonno + "/cottura";
      let urls = [
        this.baseUrlPdf + '01 Pentolame Alluminio.pdf',
        this.baseUrlPdf + '02 Pentolame Rame.pdf',
        this.baseUrlPdf + '03 Pentolame assortito.pdf',
        this.baseUrlPdf + '04 Pentolame Acciaio Inox.pdf',
        this.baseUrlPdf + '05 Pentolame Inox 3Ply.pdf',
        this.baseUrlPdf + '06 Accessori da cucina.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'Pentolame Alluminio',
        'Pentolame Rame',
        'Pentolame assortito',
        'Pentolame Acciaio Inox',
        'Pentolame Inox 3Ply',
        'Accessori da cucina',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'alim' && this.pdf == 'pentolame') {
      this.breadcrumbs = {
        ambiente: "Settore Alimentare",
        sottoambiente: "Cottura",
        catalogo: "Pentolame"
      };

      this.urlNonno = "/settore-alimentare";
      this.urlPadre = this.urlNonno + "/cottura";
      let urls = [
        this.baseUrlPdf + '01 Pentolame Alluminio.pdf',
        this.baseUrlPdf + '02 Pentolame Rame.pdf',
        this.baseUrlPdf + '03 Pentolame assortito.pdf',
        this.baseUrlPdf + '04 Pentolame Acciaio Inox.pdf',
        this.baseUrlPdf + '05 Pentolame Inox 3Ply.pdf',
        this.baseUrlPdf + '06 Accessori da cucina.pdf',
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'Pentolame Alluminio',
        'Pentolame Rame',
        'Pentolame assortito',
        'Pentolame Acciaio Inox',
        'Pentolame Inox 3Ply',
        'Accessori da cucina',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'cleaning' && this.pdf == 'lavapavimenti') {
      this.breadcrumbs = {
        ambiente: "Cleaning",
        sottoambiente: "Macchine",
        catalogo: "Macchine per pulizia"
      };

      this.urlNonno = "/cleaning";
      this.urlPadre = this.urlNonno + "/macchine-pulizia";
      let urls = [
        this.baseUrlPdf + 'PROMOZIONE USATO - LAVAPAVIMENTI 2023.pdf'
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
      ];
      let names = [
        'Offerte Usato - Lavapavimenti',
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }
    else if (this.path == 'colazione' && this.pdf == 'buffet') {
      this.breadcrumbs = {
        ambiente: "Hotel",
        sottoambiente: "Colazione",
        catalogo: "Buffet"
      };

      this.urlNonno = "/hotellerie";
      this.urlPadre = this.urlNonno + "/colazione";
      let urls = [
        this.baseUrlPdf + 'Centrifuga - estrattori.pdf',
        this.baseUrlPdf + 'Contenitori termici.pdf',
        this.baseUrlPdf + 'Crepiera professionale.pdf',
        this.baseUrlPdf + 'Cuocitori sottovuoto SOUS-VIDE.pdf',
        this.baseUrlPdf + 'Frullatori Blender.pdf',
        this.baseUrlPdf + 'Grill a salamandra.pdf',
        this.baseUrlPdf + 'Gyros kebab.pdf',
        this.baseUrlPdf + 'Microonde professionali.pdf',
        this.baseUrlPdf + 'Piastre caffetteria.pdf',
        this.baseUrlPdf + 'Sottovuoto gamma Sensor ULTRA.pdf',
        this.baseUrlPdf + 'Spremiagrumi professionali.pdf',
        this.baseUrlPdf + 'Tostiere.pdf'
      ];
      let imgs = [
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg',
        this.baseUrlImg + 'REANO_LogoRed.svg'
      ];
      let names = [
        'Centrifuga - estrattori',
        'Contenitori termici',
        'Crepiera professionale',
        'Cuocitori sottovuoto sous-vide',
        'Frullatori Blender',
        'Grill a salamandra',
        'Gyros Kebab',
        'Microonde professionali',
        'Piastre caffetteria',
        'Sottovuoto gamma Sensor Ultra',
        'Spremiagrumi professionali',
        'Tostiere'
      ];
      for (let i = 0; i < urls.length; i++) {

        this.urlpdf[i] = new Catalogo(imgs[i], urls[i], names[i])
      }

    }

  }

}


export class Catalogo {
  img;
  url;
  nome;
  constructor(img, url, nome) {
    this.img = img,
      this.url = url,
      this.nome = nome
  }
}