import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FormPreventivoComponent } from '../form-preventivo/form-preventivo.component';

@Component({
  selector: 'app-fm-at-miele',
  templateUrl: './fm-at-miele.component.html',
  styleUrls: ['./fm-at-miele.component.scss']
})
export class FmAtMieleComponent implements OnInit {

  toggolino=false;
  ics=false;
  constructor(public dialog: MatDialog) { }
apriForm(){
  if(!this.toggolino){
    this.ics=!this.ics
    document.getElementById("fc").classList.add("muovi")
    this.toggolino=!this.toggolino;
  }else{
    document.getElementById("fc").classList.remove("muovi")
    this.toggolino=!this.toggolino;
    this.ics=!this.ics
  }
  
}
  apriDialogoContatti(){
    document.querySelector(".mobile-form")[0].style.left = '-80vw';
  }
  openDialog() {
    const dialogRef = this.dialog.open(FormPreventivoComponent);

    // dialogRef.afterClosed().subscribe(result => {
    //   console.log(`Dialog result: ${result}`);
    // });
  }
  ngOnInit() {
  }

}
