import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-attrezzi-pulizia',
  templateUrl: './attrezzi-pulizia.component.html',
  styleUrls: ['./attrezzi-pulizia.component.scss']
})
export class AttrezziPuliziaComponent implements OnInit {

  constructor(private router: Router) { }
  hos: boolean;
  ngOnInit() {
    if (this.router.url == "/hospital/attrezzatura") {
      this.hos = true;
    }
    window.scrollTo(0, 0);
  }

}
