import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hotel-lavanderia',
  templateUrl: './hotel-lavanderia.component.html',
  styleUrls: ['./hotel-lavanderia.component.scss']
})
export class HotelLavanderiaComponent implements OnInit {

  constructor(private route: Router) { }
  hos:boolean;
  ngOnInit() {
    if(this.route.url=="/hospital/lavanderia"){
      this.hos=true;
    }
  }

}
