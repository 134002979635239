import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-uk',
  templateUrl: './uk.component.html',
  styleUrls: ['./uk.component.scss']
})
export class UkComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
