import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ristorazione',
  templateUrl: './ristorazione.component.html',
  styleUrls: ['./ristorazione.component.scss']
})
export class RistorazioneComponent implements OnInit {
  loading: boolean;
  constructor() { 
  this.loading=true;
  }

  load(){
    return true;
  }
  ngOnInit() {
   
    // window.scrollTo(0,0);

  }
  ngAfterViewInit(){
    this.loading=false;
 }

}
