import { ChangeDetectorRef } from '@angular/core';
import { ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateCompiler, TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-menubar',
  templateUrl: './menubar.component.html',
  styleUrls: ['./menubar.component.scss']
})


export class MenubarComponent implements OnInit {
  @Output() lading: EventEmitter<boolean> = new EventEmitter<boolean>();
  it: boolean;
  en: boolean;
  fr: boolean;
  loading: boolean;
  constructor(private route: Router, private cdRef: ChangeDetectorRef, public translate: TranslateService) {
    translate.addLangs(['it', 'fr', 'en']);
    translate.setDefaultLang('it');
    this.it = false;
    this.en = true;
    this.fr = true;
    if (this.translate.currentLang == 'it') {
      this.it = false;
      this.en = true;
      this.fr = true;
    } else if (this.translate.currentLang == 'en') {
      this.en = false;
      this.it = true;
      this.fr = true;
    } else if (this.translate.currentLang == 'fr') {
      this.fr = false;
      this.it = true;
      this.en = true;
    }
    // translate.use('it')
  }

  ll: boolean;
  status: boolean;
  isHomepage: boolean;
  // public static loader:boolean=false;

  ngOnInit() {
    // document.getElementsByClassName("loader")[0].classList.add("show");

    if (this.route.url == "/hotellerie") {
      document.getElementsByClassName("menu")[0].classList.add("active");
    } else if (this.route.url == "/hotellerie/linea-cortesia") {
      document.getElementsByClassName("menu")[0].classList.add("active");
    } else if (this.route.url == "/hotellerie/lavanderia") {
      document.getElementsByClassName("menu")[0].classList.add("active");
    } else if (this.route.url == "/hotellerie/colazione") {
      document.getElementsByClassName("menu")[0].classList.add("active");
    } else if (this.route.url == "/hotellerie/camera") {
      document.getElementsByClassName("menu")[0].classList.add("active");
    } else if (this.route.url == "/hotellerie/pulizia") {
      document.getElementsByClassName("menu")[0].classList.add("active");
    } else if (this.route.url == "/hotellerie/bagni") {
      document.getElementsByClassName("menu")[0].classList.add("active");
    }
    else if (this.route.url == "/ristorazione") {
      document.getElementsByClassName("menu")[1].classList.add("active");
    } else if (this.route.url == "/ristorazione/cottura") {
      document.getElementsByClassName("menu")[1].classList.add("active");
    } else if (this.route.url == "/ristorazione/lavaggio") {
      document.getElementsByClassName("menu")[1].classList.add("active");
    } else if (this.route.url == "/ristorazione/freddo") {
      document.getElementsByClassName("menu")[1].classList.add("active");
    } else if (this.route.url == "/ristorazione/tavola-buffet") {
      document.getElementsByClassName("menu")[1].classList.add("active");
    } else if (this.route.url == "/ristorazione/asporto") {
      document.getElementsByClassName("menu")[1].classList.add("active");
    } else if (this.route.url == "/ristorazione/caffetteria") {
      document.getElementsByClassName("menu")[1].classList.add("active");
    } else if (this.route.url == "/ristorazione/bagni") {
      document.getElementsByClassName("menu")[1].classList.add("active");
    }
    else if (this.route.url == "/cleaning") {
      document.getElementsByClassName("menu")[2].classList.add("active");
    } else if (this.route.url == "/cleaning/detergenza") {
      document.getElementsByClassName("menu")[2].classList.add("active");
    } else if (this.route.url == "/cleaning/sanificazione") {
      document.getElementsByClassName("menu")[2].classList.add("active");
    } else if (this.route.url == "/cleaning/macchine-pulizia") {
      document.getElementsByClassName("menu")[2].classList.add("active");
    } else if (this.route.url == "/cleaning/deodoranti") {
      document.getElementsByClassName("menu")[2].classList.add("active");
    } else if (this.route.url == "/cleaning/attrezzi-pulizia") {
      document.getElementsByClassName("menu")[2].classList.add("active");
    }
    else if (this.route.url == "/settore-alimentare") {
      document.getElementsByClassName("menu")[3].classList.add("active");
    } else if (this.route.url == "/settore-alimentare/cottura") {
      document.getElementsByClassName("menu")[3].classList.add("active");
    } else if (this.route.url == "/settore-alimentare/asporto") {
      document.getElementsByClassName("menu")[3].classList.add("active");
    } else if (this.route.url == "/settore-alimentare/lavaggio") {
      document.getElementsByClassName("menu")[3].classList.add("active");
    } else if (this.route.url == "/settore-alimentare/conservazione-stoccaggio") {
      document.getElementsByClassName("menu")[3].classList.add("active");
    } else if (this.route.url == "/settore-alimentare/preparazione-dinamica") {
      document.getElementsByClassName("menu")[3].classList.add("active");
    } else if (this.route.url == "/settore-alimentare/freddo") {
      document.getElementsByClassName("menu")[3].classList.add("active");
    }

    else if (this.route.url == "/hospital") {
      document.getElementsByClassName("menu")[4].classList.add("active");
    } else if (this.route.url == "/hospital/lavanderia") {
      document.getElementsByClassName("menu")[4].classList.add("active");
    } else if (this.route.url == "/hospital/cucina") {
      document.getElementsByClassName("menu")[4].classList.add("active");
    } else if (this.route.url == "/hospital/attrezzatura") {
      document.getElementsByClassName("menu")[4].classList.add("active");
    } else if (this.route.url == "/hospital/detergenza") {
      document.getElementsByClassName("menu")[4].classList.add("active");
    } else if (this.route.url == "/hospital/bagni") {
      document.getElementsByClassName("menu")[4].classList.add("active");
    }
    else if (this.route.url == "/hospital/monouso") {
      document.getElementsByClassName("menu")[4].classList.add("active");
    }
    else if (this.route.url == "/outdoor") {
      document.getElementsByClassName("menu")[5].classList.add("active");
    } else if (this.route.url == "/outdoor/giardini") {
      document.getElementsByClassName("menu")[5].classList.add("active");
    } else if (this.route.url == "/outdoor/dehore") {
      document.getElementsByClassName("menu")[5].classList.add("active");
    }
    else if (this.route.url == "/progetti") {
      document.getElementsByClassName("menu")[6].classList.add("active");
    } else if (this.route.url == "/progetti/cucine") {
      document.getElementsByClassName("menu")[6].classList.add("active");
    } else if (this.route.url == "/progetti/lavanderie") {
      document.getElementsByClassName("menu")[6].classList.add("active");
    } else if (this.route.url == "/progetti/aree-esterne") {
      document.getElementsByClassName("menu")[6].classList.add("active");
    }
    else if (this.route.url == "/consulenze") {
      document.getElementsByClassName("menu")[6].classList.add("active");
    } else if (this.route.url == "/consulenze/consulenza-tecniche") {
      document.getElementsByClassName("menu")[6].classList.add("active");
    } else if (this.route.url == "/consulenze/consulenze-tecniche-miele") {
      document.getElementsByClassName("menu")[6].classList.add("active");
    }
    else if (this.route.url == "/consulenze/nuove-aperture") {
      document.getElementsByClassName("menu")[6].classList.add("active");
    }

    else if (this.route.url == "/facility-management") {
      document.getElementsByClassName("menu")[6].classList.add("active");
    } else if (this.route.url == "/facility-management/assistenza-tecnica") {
      document.getElementsByClassName("menu")[6].classList.add("active");
    } else if (this.route.url == "/facility-management/assistenza-tecnica-miele") {
      document.getElementsByClassName("menu")[6].classList.add("active");
    }
    else if (this.route.url == "/contatti") {
      document.getElementsByClassName("menu")[8].classList.add("active");
    }
    else if (this.route.url == "/news") {
      document.getElementsByClassName("menu")[7].classList.add("active");
    }

    this.status = false;


    if (this.route.url == "/") {
      this.isHomepage = true;
      document.getElementById("barra").classList.add("no-bg");
      document.getElementById("mend").classList.add("mhp");

    } else {
      this.isHomepage = false;
    }



  }


  toggleMenu() {
    if (!this.status) {
      document.getElementById("mm").style.transform = "scaleX(1)";
      document.getElementById("mmc").style.opacity = "1";

    } else {
      document.getElementById("mmc").style.opacity = "0";
      document.getElementById("mm").style.transform = "scaleX(0)";

    }
    this.status = !this.status;
    console.log(this.status)
  }

  public changeLang(l) {
    // MenubarComponent.loader=true;
    // this.loading.emit(true);
    this.ll = true;

    if (l == 'it') {
      this.it = false;
      this.en = true;
      this.fr = true;
    } else if (l == 'en') {
      this.en = false;
      this.it = true;
      this.fr = true;
    } else if (l == 'fr') {
      this.fr = false;
      this.it = true;
      this.en = true;
    }
    this.translate.use(l)
    setTimeout(() => {
      // this.loading.emit(false);  
      this.ll = false;


    }, 1800)


  }
}




