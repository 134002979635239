import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-dettaglio-news',
    templateUrl: './dettaglio-news.html',
    styleUrls: ['./dettaglio-news.scss']
})
export class DettaglioNews implements OnInit {

    constructor(private route: ActivatedRoute, private http: HttpClient) { }
    id;
    url;
    news;
    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            this.id = params.get('id');
            this.url = "https://manage.reano.net/api/news/" + this.id;
            this.getNews().subscribe((data) => {
                console.log(data);
                this.news = data[0]

            });
        });

    }

    getNews() {
        return this.http.get<any>(this.url);
    }


}
