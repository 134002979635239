import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-admin-menu',
    templateUrl: './admin-menu.html',
    styleUrls: ['./admin-menu.scss']
})
export class AdminMenuComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
