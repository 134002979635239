import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alim-asporto',
  templateUrl: './alim-asporto.component.html',
  styleUrls: ['./alim-asporto.component.scss']
})
export class AlimAsportoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0,0)
  }

}
