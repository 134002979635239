import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-risto-accessori-cucina',
  templateUrl: './risto-accessori-cucina.component.html',
  styleUrls: ['./risto-accessori-cucina.component.scss']
})
export class RistoAccessoriCucinaComponent implements OnInit {

  constructor(private route: Router) { }
  sa:boolean;
  ngOnInit() {
    this.sa=false;
    if(this.route.url=="/settore-alimentare/cottura"){
      this.sa=true;
    }
    window.scrollTo(0,0);
  }

}
