import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
class ImageSnippet {
    constructor(public src: string, public file: File) { }
}
@Component({
    selector: 'app-img-dialog-add',
    templateUrl: './add.img.dialog.html',
    styleUrls: ['./add.img.dialog.scss']
})
export class DialogAddImgComponent implements OnInit {
    selectedFile: ImageSnippet;

    // constructor(private imageService: ImageService) { }
    constructor() { }
    ngOnInit() {
    }

    // processFile(imageInput: any) {
    //     const file: File = imageInput.files[0];
    //     const reader = new FileReader();

    //     reader.addEventListener('load', (event: any) => {

    //         this.selectedFile = new ImageSnippet(event.target.result, file);

    //         this.imageService.uploadImage(this.selectedFile.file).subscribe(
    //             (res) => {

    //             },
    //             (err) => {

    //             })
    //     });

    //     reader.readAsDataURL(file);
    // }

}
// export class ImageService {

//     constructor(private http: HttpClient) { }


//     public uploadImage(image: File): Observable<Response> {
//         const formData = new FormData();

//         formData.append('image', image);

//         return this.http.post('/api/v1/image-upload', formData);
//     }
// }