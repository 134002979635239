import { Component, HostListener, OnInit } from '@angular/core';
declare let ScrollMagic: any;

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  // @HostListener('document:mousewheel', ['$event'])
  // onWheel(e) {
  //   console.log(e.deltaY)
  //   console.log(e);
  //   var cerchi = document.getElementsByClassName("cerchio")
    
  //     for(var i =0; i<cerchi.length; i++){
  //       if (e.deltaY >= 300) {
  //         document.getElementById('d').classList.add("selected")
  //         cerchi[i].classList.add("c-sel")

  //     }
  //     else if (e.deltaY <= 300) {

  //       console.log("cacca")
        
  //       document.getElementById('d').classList.remove("selected")
  //       cerchi[i].classList.remove("c-sel")
        
        
  //           }
    
  //   } 
  // }

  addSel(){
    var cerchio = document.getElementById("c");
    var anno = document.getElementById("d");
    cerchio.classList.add("c-sel")
    anno.classList.add("selected")
  }

  ngOnInit() {
   console.log(window.innerHeight)

  

 


    // var controller = new ScrollMagic.Controller();

    // var scene = new ScrollMagic.Scene({ triggerElement: "#riga" })
    // .setPin('#riga', 'c-sel')
    // .addTo(controller);
   

    const seiquattroCtrl = new ScrollMagic.Controller();
    const seiquattroScene = new ScrollMagic.Scene({
      triggerElement: '#seiquattro',
      triggerHook: 0.8
    })
    // .setTween(tween)
    // .setClassToggle('#seiquattrocir', 'c-sel')
    .setClassToggle('#seiquattro', 'selected')
      // .addIndicators()
          .addTo(seiquattroCtrl);

          const seiquattrocirCtrl = new ScrollMagic.Controller();
    const seiquattrocirScene = new ScrollMagic.Scene({
      triggerElement: '#seiquattro',
      triggerHook: 0.8
    })
    // .setTween(tween)
    .setClassToggle('#seiquattrocir', 'c-sel')
    // .setClassToggle('#seiquattro', 'selected')
      // .addIndicators()
          .addTo(seiquattrocirCtrl);


    const settCtrl = new ScrollMagic.Controller();
    const settScene = new ScrollMagic.Scene({
      triggerElement: '#sette',
      triggerHook: 0.40
    })
    .setClassToggle('#settecir', 'c-sel')
      // .addIndicators()
          .addTo(settCtrl);

          const csettCtrl = new ScrollMagic.Controller();
          const csettScene = new ScrollMagic.Scene({
            triggerElement: '#sette',
            triggerHook: 0.40
          })
          .setClassToggle('#sette', 'selected')
            // .addIndicators()
                .addTo(csettCtrl);

    
                const ottCtrl = new ScrollMagic.Controller();
                const ottScene = new ScrollMagic.Scene({
                  triggerElement: '#otto',
                  triggerHook: 0.40
                })
                .setClassToggle('#ottocir', 'c-sel')
                  // .addIndicators()
                      .addTo(ottCtrl);
            
                      const cottCtrl = new ScrollMagic.Controller();
                      const cottScene = new ScrollMagic.Scene({
                        triggerElement: '#otto',
                        triggerHook: 0.40
                      })
                      .setClassToggle('#otto', 'selected')
                        // .addIndicators()
                            .addTo(cottCtrl);
      



  }
  


}
