import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-rist-tavola',
  templateUrl: './rist-tavola.component.html',
  styleUrls: ['./rist-tavola.component.scss']
})
export class RistTavolaComponent implements OnInit {

  constructor(private rr: Router) { }
  ris: boolean = false;

  ngOnInit() {

    if (this.rr.url == "/ristorazione/tavola-buffet") {
      this.ris = true;
    }

    window.scrollTo(0, 0);
  }

}
