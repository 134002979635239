import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bagni',
  templateUrl: './bagni.component.html',
  styleUrls: ['./bagni.component.scss']
})
export class BagniComponent implements OnInit {

  constructor(private route: Router) { }
  ri:boolean=false;
  ho:boolean=false;
  hop:boolean=false;
  ngOnInit() {
    if(this.route.url=="/ristorazione/bagni"){
      
      this.ri=true;
    }else if(this.route.url=="/hotellerie/bagni"){
      this.ho=true;
    }else if(this.route.url=="/hospital/bagni"){
      this.hop=true;
    }
    window.scrollTo(0,0)
  }

}
