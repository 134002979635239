import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ris-lavaggio',
  templateUrl: './ris-lavaggio.component.html',
  styleUrls: ['./ris-lavaggio.component.scss']
})
export class RisLavaggioComponent implements OnInit {

  constructor(private route: Router) { }
  sa:boolean;
  ngOnInit() {
    this.sa=false;
    if(this.route.url=="/settore-alimentare/lavaggio"){
      this.sa=true;
    }
    window.scrollTo(0,0);
  }

}
