import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hotel-cortesia',
  templateUrl: './hotel-cortesia.component.html',
  styleUrls: ['./hotel-cortesia.component.scss']
})
export class HotelCortesiaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
