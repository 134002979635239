import { Component, OnInit } from '@angular/core';
import Swiper from 'swiper';
import * as $ from 'jquery';
import {MatDialog} from '@angular/material/dialog';
import { DialogcontattiComponent } from '../dialogcontatti/dialogcontatti.component';
import { FormPreventivoComponent } from '../form-preventivo/form-preventivo.component';
@Component({
  selector: 'app-cucine-prog-esplosione',
  templateUrl: './cucine-prog-esplosione.component.html',
  styleUrls: ['./cucine-prog-esplosione.component.scss']
})

export class CucineProgEsplosioneComponent implements OnInit {
  toggolino=false;
  ics=false;
  constructor(public dialog: MatDialog) { }
apriForm(){
  if(!this.toggolino){
    this.ics=!this.ics
    document.getElementById("fc").classList.add("muovi")
    this.toggolino=!this.toggolino;
  }else{
    document.getElementById("fc").classList.remove("muovi")
    this.toggolino=!this.toggolino;
    this.ics=!this.ics
  }
  
}
  apriDialogoContatti(){
    document.querySelector(".mobile-form")[0].style.left = '-80vw';
  }
  openDialog() {
    const dialogRef = this.dialog.open(FormPreventivoComponent);

    // dialogRef.afterClosed().subscribe(result => {
    //   console.log(`Dialog result: ${result}`);
    // });
  }
  ngOnInit() {


    new Swiper('.swiper-container', {
     // Optional parameters
  direction: 'horizontal',
  loop: false,
      slidesPerView:1,
  // If we need pagination
  // pagination: {
  //   el: '.swiper-pagination',
  // },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },

  // And if we need scrollbar
  // scrollbar: {
  //   el: '.swiper-scrollbar',
  // },
});
 }
 
}
