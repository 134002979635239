import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { News } from './news.list';

@Component({
    selector: 'app-edit-news',
    templateUrl: './edit.news.html',
    styleUrls: ['./edit.news.scss']
})
export class EditNewsComponent implements OnInit {

    constructor(private route: ActivatedRoute, private http: HttpClient) { }
    newsId;

    news: News;
    loading: boolean;
    showImgEvidenza: boolean;
    listaImg: Img[];
    urlImgEvidenza: string;
    modificaNewsForm = new FormGroup({
        titoloControl: new FormControl('', Validators.required),
        autoreControl: new FormControl('', Validators.required),
        immagineEvidenzaControl: new FormControl('', Validators.required),
        testoControl: new FormControl('', Validators.required),
    })

    baseUrl = "https://manage.reano.net/"
    baseUrlDev = "http://localhost:3000/"
    ngOnInit() {
        this.newsId = Number(this.route.snapshot.paramMap.get('id'));
        console.log(this.newsId)
        this.loading = true;
        this.showImgEvidenza = false;
        this.getNews(this.newsId).subscribe((data) => {
            this.news = data;
            this.modificaNewsForm.controls.titoloControl.setValue(this.news[0].titolo)
            this.modificaNewsForm.controls.autoreControl.setValue(this.news[0].autore)
            this.modificaNewsForm.controls.immagineEvidenzaControl.setValue(this.news[0].img_evidenza)
            this.modificaNewsForm.controls.testoControl.setValue(this.news[0].testo)

            console.log(this.news)
            this.getImgList().subscribe((data) => {
                console.log(data);
                this.listaImg = data;
                this.loading = false;
            });
            // this.loading = false;
        });



        this.modificaNewsForm.controls.immagineEvidenzaControl.valueChanges.pipe(
        ).subscribe(
            val => {
                console.log("imgUrl: " + val);
                this.urlImgEvidenza = val;
                this.showImgEvidenza = true;

            }
        );
    }



    getNews(newsId) {
        return this.http.get<News>(this.baseUrlDev + "news/" + newsId);

    }



    getImgList() {
        return this.http.get<Img[]>(this.baseUrl + "image-list");

    }


    editNews() {
        if (this.modificaNewsForm.valid) {
            this.loading = true;
            let news = {
                id: this.newsId,
                titolo: this.modificaNewsForm.value.titoloControl,
                testo: this.modificaNewsForm.value.testoControl,
                autore: this.modificaNewsForm.value.autoreControl,
                imgEvidenza: this.modificaNewsForm.value.immagineEvidenzaControl
            }

            console.log(news);
            return this.http.put<any>(this.baseUrlDev + "edit-news", news).subscribe(
                data => {
                    let res: any = data;
                    console.log(
                        'call done'
                    );
                    console.log(res)
                    this.loading = false;

                })
        }
        else {
            console.log("Form is not valid")
        }
    }
}


export interface Img {
    title: string;
    value: string
}
