import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CleaningDetergenzaComponent } from './components/cleaning-detergenza/cleaning-detergenza.component';
import { AboutComponent } from './components/about/about.component';
import { AmbientiComponent } from './components/ambienti/ambienti.component';
import { AsportoComponent } from './components/asporto/asporto.component';
import { BarComponent } from './components/bar/bar.component';
import { CaffetteriaRistoComponent } from './components/caffetteria-risto/caffetteria-risto.component';
import { ConsTMieleComponent } from './components/cons-t-miele/cons-t-miele.component';
import { ConsTComponent } from './components/cons-t/cons-t.component';
import { ConsulenzeComponent } from './components/consulenze/consulenze.component';
import { ContattiComponent } from './components/contatti/contatti.component';
import { CucineProgEsplosioneComponent } from './components/cucine-prog-esplosione/cucine-prog-esplosione.component';
import { DehoreProgEsplComponent } from './components/dehore-prog-espl/dehore-prog-espl.component';
import { DettaglioAmbienteComponent } from './components/dettaglio-ambiente/dettaglio-ambiente.component';
import { FacilityComponent } from './components/facility/facility.component';
import { FmAtMieleComponent } from './components/fm-at-miele/fm-at-miele.component';
import { FmAtComponent } from './components/fm-at/fm-at.component';
import { FoodComponent } from './components/food/food.component';
import { FreddoComponent } from './components/freddo/freddo.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { LavaProgEsplosioneComponent } from './components/lava-prog-esplosione/lava-prog-esplosione.component';
import { NuoveApertureComponent } from './components/nuove-aperture/nuove-aperture.component';
import { OfficeComponent } from './components/office/office.component';
import { OspitalitaComponent } from './components/ospitalita/ospitalita.component';
import { PorcellaneComponent } from './components/porcellane/porcellane.component';
import { PosateriaComponent } from './components/posateria/posateria.component';
import { ProgettiComponent } from './components/progetti/progetti.component';
import { ResidenceComponent } from './components/residence/residence.component';
import { RisLavaggioComponent } from './components/ris-lavaggio/ris-lavaggio.component';
import { RistTavolaComponent } from './components/rist-tavola/rist-tavola.component';
import { RistoAccessoriCucinaComponent } from './components/risto-accessori-cucina/risto-accessori-cucina.component';
import { RistorazioneComponent } from './components/ristorazione/ristorazione.component';
import { SanitarioAssistenzialeComponent } from './components/sanitario-assistenziale/sanitario-assistenziale.component';
import { ServiziComponent } from './components/servizi/servizi.component';
import { VetriComponent } from './components/vetri/vetri.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { CleaningSanificaComponent } from './components/cleaning-sanifica/cleaning-sanifica.component';
import { MacchinePuliziaComponent } from './components/macchine-pulizia/macchine-pulizia.component';
import { DeodorantiComponent } from './components/deodoranti/deodoranti.component';
import { AttrezziPuliziaComponent } from './components/attrezzi-pulizia/attrezzi-pulizia.component';
import { AlimCotturaComponent } from './components/alim-cottura/alim-cottura.component';
import { AlimAsportoComponent } from './components/alim-asporto/alim-asporto.component';
import { AlimLavaggioComponent } from './components/alim-lavaggio/alim-lavaggio.component';
import { AlimConsStoccaggioComponent } from './components/alim-cons-stoccaggio/alim-cons-stoccaggio.component';
import { AlimPrepDinamicaComponent } from './components/alim-prep-dinamica/alim-prep-dinamica.component';
import { HotelCortesiaComponent } from './components/hotel-cortesia/hotel-cortesia.component';
import { HotelLavanderiaComponent } from './components/hotel-lavanderia/hotel-lavanderia.component';
import { HotelColazioneComponent } from './components/hotel-colazione/hotel-colazione.component';
import { HotelBagnoComponent } from './components/hotel-bagno/hotel-bagno.component';
import { HotelPuliziaComponent } from './components/hotel-pulizia/hotel-pulizia.component';
import { CuraeriposoComponent } from './components/curaeriposo/curaeriposo.component';
import { CucinaComponent } from './components/anziani/cucina/cucina.component';
import { BagniComponent } from './components/anziani/bagni/bagni.component';
import { DetergenzaComponent } from './components/anziani/detergenza/detergenza.component';
import { LavanderiaComponent } from './components/anziani/lavanderia/lavanderia.component';
import { OutdoorComponent } from './components/outdoor/outdoor.component';
import { OutdoorgiardiniComponent } from './components/outdoor/outdoorgiardini/outdoorgiardini.component';
import { OutdoordehoreComponent } from './components/outdoor/outdoordehore/outdoordehore.component';
import { HotelCameraComponent } from './components/hotel-camera/hotel-camera.component';
import { HospitalMonousoComponent } from './components/hospital-monouso/hospital-monouso.component';
import { LandingCatalogoComponent } from './components/landing-catalogo/landing-catalogo.component';
import { RinnovolocaliComponent } from './components/rinnovolocali/rinnovolocali.component';
import { InformativaPrivacyComponent } from './components/informativa-privacy/informativa-privacy.component';
import { NewsComponent } from './components/news/news.component';
import { DettaglioNews } from './components/dettagio-news/dettaglio-news';
import { LoginComponent } from './components/admin/login/login-component';
import {
  AuthGuardService as AuthGuard
} from './auth-guard';
import { DashAdminComponent } from './components/admin/dashboard/dash-admin.component';
import { AdminNewsComponent } from './components/admin/news/news.list';
import { AdminAddNewsComponent } from './components/admin/news/add.news';
import { EditNewsComponent } from './components/admin/news/edit.news';

const routes: Routes = [
  {
    path: "404",
    component: NotfoundComponent
  },
  {
    path: "",
    component: HomepageComponent
  },
  {
    path: "about",
    component: AboutComponent
  },
  {
    path: "admin",
    component: LoginComponent,

  },
  {
    path: "admin/dashboard",
    component: DashAdminComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: "admin/news",
    component: AdminNewsComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: "admin/news/nuova",
    component: AdminAddNewsComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: "admin/news/modifica/:id",
    component: EditNewsComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: "ambienti",
    component: AmbientiComponent
  },
  {
    path: "hotellerie",
    component: OspitalitaComponent
  },
  {
    path: "hotellerie/linea-cortesia",
    component: HotelCortesiaComponent
  },
  {
    path: "hotellerie/bagni",
    component: BagniComponent
  },
  {
    path: "hospital",
    component: CuraeriposoComponent
  },
  {
    path: "hospital/cucina",
    component: CucinaComponent
  },
  {
    path: "hospital/detergenza",
    component: DetergenzaComponent
  },
  {
    path: "hospital/lavanderia",
    component: HotelLavanderiaComponent
  },
  {
    path: "hospital/bagni",
    component: BagniComponent
  },
  {
    path: "hospital/attrezzatura",
    component: AttrezziPuliziaComponent
  },
  {
    path: "hotellerie/lavanderia",
    component: HotelLavanderiaComponent
  },
  {
    path: "hotellerie/camera",
    component: HotelCameraComponent
  },
  {
    path: "outdoor",
    component: OutdoorComponent
  },
  {
    path: "outdoor/giardini",
    component: OutdoorgiardiniComponent
  },
  {
    path: "outdoor/dehore",
    component: OutdoordehoreComponent
  },
  {
    path: "news",
    component: NewsComponent
  },
  {
    path: "hotellerie/pulizia",
    component: HotelPuliziaComponent
  },
  {
    path: "hotellerie/colazione",
    component: HotelColazioneComponent
  },
  {
    path: "hospital/monouso",
    component: HospitalMonousoComponent
  },
  {
    path: "ristorazione",
    component: RistorazioneComponent
  },
  {
    path: "cleaning",
    component: SanitarioAssistenzialeComponent
  },
  {
    path: "cleaning/detergenza",
    component: CleaningDetergenzaComponent
  },
  {
    path: "cleaning/deodoranti",
    component: DeodorantiComponent
  },
  {
    path: "cleaning/attrezzi-pulizia",
    component: AttrezziPuliziaComponent
  },
  {
    path: "cleaning/sanificazione",
    component: CleaningSanificaComponent
  },
  {
    path: "cleaning/macchine-pulizia",
    component: MacchinePuliziaComponent
  },
  {
    path: "settore-alimentare",
    component: FoodComponent
  },
  {
    path: "settore-alimentare/cottura",
    component: RistoAccessoriCucinaComponent
  },
  {
    path: "settore-alimentare/freddo",
    component: FreddoComponent
  },
  {
    path: "settore-alimentare/asporto",
    component: AsportoComponent
  },
  {
    path: "settore-alimentare/preparazione-dinamica",
    component: AlimPrepDinamicaComponent
  },
  {
    path: "settore-alimentare/lavaggio",
    component: RisLavaggioComponent
  },
  {
    path: "settore-alimentare/conservazione-stoccaggio",
    component: AlimConsStoccaggioComponent
  },
  {
    path: "office",
    component: OfficeComponent
  },
  {
    path: "contatti",
    component: ContattiComponent
  },
  {
    path: "servizi",
    component: ServiziComponent
  },
  {
    path: "progetti",
    component: ProgettiComponent
  },
  {
    path: "progetti/cucine",
    component: CucineProgEsplosioneComponent
  },
  {
    path: "progetti/lavanderie",
    component: LavaProgEsplosioneComponent
  },
  {
    path: "progetti/aree-esterne",
    component: DehoreProgEsplComponent
  },
  {
    path: "progetti/rinnovo-locali",
    component: RinnovolocaliComponent
  },
  {
    path: "consulenze",
    component: ConsulenzeComponent
  },
  {
    path: "consulenze/consulenza-tecniche",
    component: ConsTComponent
  },
  {
    path: "consulenze/consulenze-tecniche-miele",
    component: ConsTMieleComponent
  },
  {
    path: "consulenze/nuove-aperture",
    component: NuoveApertureComponent
  },
  {
    path: "ristorazione/porcellane",
    component: PorcellaneComponent
  },
  {
    path: "ristorazione/bagni",
    component: BagniComponent
  },
  {
    path: "ristorazione/posateria",
    component: PosateriaComponent
  },
  // {
  //   path: "ristorazione/caffetteria",
  //   component: BarComponent
  // },
  {
    path: "ristorazione/cottura",
    component: RistoAccessoriCucinaComponent
  },
  {
    path: "ristorazione/vetri-cristalli",
    component: VetriComponent
  },
  {
    path: "ristorazione/tavola-buffet",
    component: RistTavolaComponent
  },
  {
    path: "ristorazione/asporto",
    component: AsportoComponent
  },
  {
    path: "ristorazione/caffetteria",
    component: CaffetteriaRistoComponent
  },
  {
    path: "ristorazione/asporto",
    component: RistTavolaComponent
  },
  {
    path: "ristorazione/residence",
    component: ResidenceComponent
  },
  {
    path: "ristorazione/lavaggio",
    component: RisLavaggioComponent
  },
  {
    path: "ristorazione/freddo",
    component: FreddoComponent
  },
  {
    path: "facility-management",
    component: FacilityComponent
  },
  {
    path: "facility-management/assistenza-tecnica",
    component: FmAtComponent
  },
  {
    path: "facility-management/assistenza-tecnica-miele",
    component: FmAtMieleComponent
  },
  {
    path: "ristorazione/freddo",
    component: FmAtMieleComponent
  },
  {
    path: "catalogo/:path/:pdf",
    component: LandingCatalogoComponent
  },
  {
    path: "news/:id",
    component: DettaglioNews
  },
  {
    path: "informativa-privacy",
    component: InformativaPrivacyComponent
  },



  {
    path: "**",
    redirectTo: "/404"
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
