import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
@Injectable({
    providedIn: 'root'
})
export class AuthService {
    constructor(public jwtHelper: JwtHelperService) { }
    // ...
    public isAuthenticated(): boolean {
        // Get token from localstorage
        console.log("CONTROLLO TOKEN")
        let token = sessionStorage.getItem('token');
        // console.log(token)
        // Check if token is null or empty
        if (token) {
            // Check whether the token is expired and return
            // true or false
            return !this.jwtHelper.isTokenExpired(token);
        }
        else {
            return false
        }
    }
}