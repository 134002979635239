import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sanitario-assistenziale',
  templateUrl: './sanitario-assistenziale.component.html',
  styleUrls: ['./sanitario-assistenziale.component.scss']
})
export class SanitarioAssistenzialeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0,0);

  }

}
