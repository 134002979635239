import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hotel-bagno',
  templateUrl: './hotel-bagno.component.html',
  styleUrls: ['./hotel-bagno.component.scss']
})
export class HotelBagnoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
