import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-form-preventivo',
  templateUrl: './form-preventivo.component.html',
  styleUrls: ['./form-preventivo.component.scss']
})
export class FormPreventivoComponent implements OnInit {
  messageForm = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
    telefono: new FormControl(''),
    messaggio: new FormControl('', Validators.required),
    ragioneSociale: new FormControl('', Validators.required),
    tipoAttivita: new FormControl('', Validators.required),
    nomeCognome: new FormControl('', Validators.required),
    termini: new FormControl(null, Validators.required),
  })
  file;
  isAttach: boolean = false;
  route;
  filePath;
  splitted;
  sending = false;
  openRitorno = false;
  msg;
  settori = [
    '',
    'Hotel',
    'Ristorazione',
    'Cleaning',
    'Settore Alimentare',
    'Hospital',
    'Altro'
  ];


  constructor(private http: HttpClient, private router: Router) { }

  ngOnInit() {

    this.filePath = null;
    this.route = this.router.url;
    this.splitted = this.route.split("/", 2)[1];
    console.log(this.splitted)
  }
  selectFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.file = file;
      if (this.file) {
        this.isAttach = true;
      }
    }
  }

  noerror(el) {
    if (el == 'email') {
      document.getElementById(el).classList.remove('error')
    } else if (el == 'tel') {
      document.getElementById(el).classList.remove('error')
    } else if (el == 'msg') {
      document.getElementById(el).classList.remove('error')
    }
    else if (el == 'termini') {
      document.getElementById(el).classList.remove('error')
    }
    else if (el == 'nomeCognome') {
      document.getElementById(el).classList.remove('error')
    }
    else if (el == 'ragioneSociale') {
      document.getElementById(el).classList.remove('error')
    }
    else if (el == 'tipoAttivita') {
      document.getElementById(el).classList.remove('error')
    }
  }


  apriRitorno(msg) {
    this.openRitorno = true;
    this.msg = msg
  }


  sendMsg() {
    if (this.messageForm.valid && this.messageForm.controls.email.value != '' && this.messageForm.controls.termini.value) {
      let mmmm = this.messageForm.controls.messaggio.value
      console.log("SELECT::: " + this.messageForm.controls.tipoAttivita.value)
      this.sending = true;

      let emailObj = {
        message: mmmm,
        from: this.messageForm.controls.email.value,
        oggetto: 'Messaggio dal sito.',
        telefono: this.messageForm.controls.telefono.value.toUpperCase(),
        categoria: this.splitted.toUpperCase(),
        tipoAttivita: this.messageForm.controls.tipoAttivita.value.toUpperCase(),
        nomeCognome: this.messageForm.controls.nomeCognome.value.toUpperCase(),
        ragioneSociale: this.messageForm.controls.ragioneSociale.value.toUpperCase(),

        // categoria: this.route.split("/", 2)[1].toUpperCase
        // path: this.filePath
      }
      console.log(emailObj)


      this.http.post("https://www.reano.net/mail", emailObj, { responseType: 'text' }).subscribe(
        (data) => {
          this.sending = false;
          this.apriRitorno("Messaggio inviato");
          window.location.reload();
        },
        (err) => {
          this.sending = false;
          this.apriRitorno("Qualcosa è andato storto! Riprova più tardi.");

          setTimeout(() => {
            window.location.reload();
          }, 3000)

          console.log(err);
        }
      )
    } else {
      let email = this.messageForm.controls.email.valid;
      let emailv = this.messageForm.controls.email.value;
      let msg = this.messageForm.controls.messaggio.valid;
      let nc = this.messageForm.controls.nomeCognome.valid;
      let ncv = this.messageForm.controls.nomeCognome.value;
      let rs = this.messageForm.controls.ragioneSociale.valid;
      let rsv = this.messageForm.controls.ragioneSociale.value;
      let sel = this.messageForm.controls.tipoAttivita.valid;
      let selv = this.messageForm.controls.tipoAttivita.value;
      let msgv = this.messageForm.controls.messaggio.value;
      if (emailv == '' || !email) {
        console.log("error")
        document.getElementById('email').classList.add('error')
        // document.getElementById('emailp').classList.add('error')
      }
      if (selv == '' || !sel) {
        console.log("error")
        document.getElementById('tipoAttivita').classList.add('error')
        // document.getElementById('emailp').classList.add('error')
      }
      if (rsv == '' || !rs) {
        console.log("error")
        document.getElementById('ragioneSociale').classList.add('error')
        // document.getElementById('emailp').classList.add('error')
      }
      if (ncv == '' || !nc) {
        console.log("error")
        document.getElementById('nomeCognome').classList.add('error')
        // document.getElementById('emailp').classList.add('error')
      }
      if (msgv == '' || !msg) {
        console.log("error")
        document.getElementById('msg').classList.add('error')
        // document.getElementById('emailp').classList.add('error')
      }
      if (this.messageForm.controls.termini.value == null || !this.messageForm.controls.termini.value) {
        document.getElementById('termini').classList.add('error')
      }

    }

  }

  onSubmit() {
    if (this.messageForm.valid && this.messageForm.controls.email.value != '' && this.messageForm.controls.termini.value) {
      console.log("valid")
      const formData = new FormData();

      formData.append('file', this.file);



      console.log(formData)
      let path;
      //imposto header
      let headers = new HttpHeaders({
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiJ9.ZGllZ28.XQsJKzu46d33krSkaztIKvAdDTS7EffoLwlmp_PUWJI'
      })
      let headerse = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiJ9.ZGllZ28.XQsJKzu46d33krSkaztIKvAdDTS7EffoLwlmp_PUWJI'
      })
      let options = { headers: headers };
      let optionse = {
        headers: headers
      },
        responseType: 'json';

      //uploaddo immagine
      this.http.post<any>(environment.url + "/file", formData, options).subscribe(
        (res) => {
          path = res.path;
          this.filePath = res.path;
          console.log(path)
          let emailObj = {
            message: this.messageForm.controls.messaggio.value,
            from: this.messageForm.controls.email.value,
            oggetto: 'Messaggio dal sito.',
            telefono: this.messageForm.controls.telefono.value,
            categoria: this.route.split("/", 2)[1].toUpperCase,
            path: this.filePath
          }



          setTimeout(() => {
            console.log('send');
            this.http.post<any>(environment.url + "/send", emailObj, optionse).subscribe(
              (data) => {
                // window.location.reload()
              },
              (err) => {
                console.log(err);
              }
            );
          }, 3000);
        },
        (err) => console.log(err)
      );


      if (this.route) {

      }


      //   let emailObj = {
      //     message: this.messageForm.controls.messaggio.value,
      //     from: this.messageForm.controls.email.value,
      //     oggetto: 'Messaggio dal sito.',
      //     telefono: this.messageForm.controls.telefono.value,
      //     categoria: this.route.split("/", 2)[1].toUpperCase,
      //     path: this.filePath
      //   }



      //   setTimeout(()=>{
      //     console.log('send');
      //   this.http.post<any>(environment.url + "/send", emailObj, optionse).subscribe(
      //     (data) => {
      //       // window.location.reload()
      //     },
      //     (err) => {
      //       console.log(err);
      //     }
      //   );
      // },3000);
    } else {

      let email = this.messageForm.controls.email.valid;
      let emailv = this.messageForm.controls.email.value;
      let tel = this.messageForm.controls.telefono.valid;
      let msg = this.messageForm.controls.messaggio.valid;
      let msgv = this.messageForm.controls.messaggio.value;

      //errori sui campi del form
      // if (emailv == '' || (emailv == '' && !msgv)) {
      //   document.getElementById('email').classList.add('error')
      // }
      // if ((!email && !msg) || (emailv == null && msgv == null) || (msgv == '' && emailv == '') || (msgv == null && emailv == '') || (msgv == '' && emailv == null)) {
      //   document.getElementById('email').classList.add('error')
      //   document.getElementById('msg').classList.add('error')
      // } else if ((!email && msg) || (emailv == null && msg) || (emailv == '' && msg) || (emailv == '' && msgv != null) || (emailv == '' && msgv != '')) {
      //   document.getElementById('email').classList.add('error')
      // } else if ((!msg && email) || (msgv == null && email) || (msgv == '' && email)) {
      //   document.getElementById('msg').classList.add('error')
      // } if (this.messageForm.controls.termini.value == null || !this.messageForm.controls.termini.value) {
      //   document.getElementById('termini').classList.add('error')
      // }
      let nc = this.messageForm.controls.nomeCognome.valid;
      let ncv = this.messageForm.controls.nomeCognome.value;
      let rs = this.messageForm.controls.ragioneSociale.valid;
      let rsv = this.messageForm.controls.ragioneSociale.value;
      let sel = this.messageForm.controls.tipoAttivita.valid;
      let selv = this.messageForm.controls.tipoAttivita.value;

      if (selv == '' || !sel) {
        console.log("error")
        document.getElementById('tipoAttivita').classList.add('error')
        // document.getElementById('emailp').classList.add('error')
      }
      if (rsv == '' || !rs) {
        console.log("error")
        document.getElementById('ragioneSociale').classList.add('error')
        // document.getElementById('emailp').classList.add('error')
      }
      if (ncv == '' || !nc) {
        console.log("error")
        document.getElementById('nomeCognome').classList.add('error')
        // document.getElementById('emailp').classList.add('error')
      }
      if (emailv == '' || !email) {
        console.log("error")
        document.getElementById('email').classList.add('error')
        // document.getElementById('emailp').classList.add('error')
      }
      if (msgv == '' || !msg) {
        console.log("error")
        document.getElementById('msg').classList.add('error')
        // document.getElementById('emailp').classList.add('error')
      }
      if (this.messageForm.controls.termini.value == null || !this.messageForm.controls.termini.value) {
        document.getElementById('termini').classList.add('error')
      }
    }
  }//submit end
}
