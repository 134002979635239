import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-msgritorno',
  templateUrl: './msgritorno.component.html',
  styleUrls: ['./msgritorno.component.scss']
})
export class MsgritornoComponent implements OnInit {
  @Input() msg: string;
  constructor() { }

  ngOnInit() {
  }

}
