import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { animationFrameScheduler } from 'rxjs';
import { DialogAddImgComponent } from './dialog/add.img.dialog';

@Component({
    selector: 'app-admin-add-news',
    templateUrl: './add.news.html',
    styleUrls: ['./add.news.scss']
})

export class AdminAddNewsComponent implements OnInit {
    loading: boolean;
    showImgEvidenza: boolean;
    listaImg: Img[];
    urlImgEvidenza: string;
    nuovaNewsForm = new FormGroup({
        titoloControl: new FormControl('', Validators.required),
        autoreControl: new FormControl('', Validators.required),
        immagineEvidenzaControl: new FormControl('', Validators.required),
        testoControl: new FormControl('', Validators.required),
    })

    baseUrl = "https://manage.reano.net/"
    baseUrlDev = "http://localhost:3000/"

    constructor(private http: HttpClient, public dialog: MatDialog) { }

    openAddImgDialog() {
        this.dialog.open(DialogAddImgComponent);
    }
    ngOnInit() {
        this.loading = true;
        this.showImgEvidenza = false;
        this.getImgList().subscribe((data) => {
            console.log(data);
            this.listaImg = data;
            this.loading = false;
        });


        this.nuovaNewsForm.controls.immagineEvidenzaControl.valueChanges.pipe(
        ).subscribe(
            val => {
                console.log("imgUrl: " + val);
                this.urlImgEvidenza = val;
                this.showImgEvidenza = true;

            }
        );
    }

    getImgList() {
        return this.http.get<Img[]>(this.baseUrl + "image-list");

    }


    insertNews() {
        if (this.nuovaNewsForm.valid) {
            this.loading = true;
            let news = {
                titolo: this.nuovaNewsForm.value.titoloControl,
                testo: this.nuovaNewsForm.value.testoControl,
                autore: this.nuovaNewsForm.value.autoreControl,
                imgEvidenza: this.nuovaNewsForm.value.immagineEvidenzaControl
            }

            console.log(news);
            return this.http.post<any>(this.baseUrlDev + "insert-news", news).subscribe(
                data => {
                    let res: any = data;
                    console.log(
                        'call done'
                    );
                    console.log(res)
                    this.loading = false;

                })
        }
        else {
            console.log("Form is not valid")
        }
    }
}


export interface Img {
    title: string;
    value: string
}