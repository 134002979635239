import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dettaglio-ambiente',
  templateUrl: './dettaglio-ambiente.component.html',
  styleUrls: ['./dettaglio-ambiente.component.scss']
})
export class DettaglioAmbienteComponent implements OnInit {

  constructor(private route: Router) { }
  ospitalita: boolean = false;
  ristorazione: boolean = false;
  sanitario: boolean = false;
  food: boolean = false;
  office: boolean = false;
  page: string;
  pages = [
    'Ospitalità',
    'Ristorazione',
    'Sanitario ed Assistenziale',
    'Food',
    'Office'
  ];
  btn: string;
  ngOnInit() {
    if (this.route.url == "/ambienti/ospitalita") {
      this.ospitalita = true;
      this.page = this.pages[0];
      this.btn = this.pages[1];
    } else if (this.route.url == "/ambienti/ristorazione") {
      this.ristorazione = true;
      this.page = this.pages[1];
      this.btn = this.pages[2];
    } else if (this.route.url == "/ambienti/sanitario-assistenziale") {
      this.sanitario = true;
      this.page = this.pages[2];
      this.btn = this.pages[3];
    } else if (this.route.url == "/ambienti/food") {
      this.food = true;
      this.page = this.pages[3];
      this.btn = this.pages[4];
    } else if (this.route.url == "/ambienti/office") {
      this.office = true;
      this.page = this.pages[4];
      this.btn = this.pages[0];
    }
  }

}
