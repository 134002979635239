import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hotel-pulizia',
  templateUrl: './hotel-pulizia.component.html',
  styleUrls: ['./hotel-pulizia.component.scss']
})
export class HotelPuliziaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
