import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-caffetteria-risto',
  templateUrl: './caffetteria-risto.component.html',
  styleUrls: ['./caffetteria-risto.component.scss']
})
export class CaffetteriaRistoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0,0);
  }

}
