import { HttpClient } from '@angular/common/http';
import { SecurityContext } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) { }
  url = "https://manage.reano.net/api/news";
  newsList = [];
  imgUrl: SafeUrl = [];
  l;
  ngOnInit() {
    this.getNews().subscribe((data) => {
      console.log(data);
      this.newsList = data;
      for (this.l = 0; this.l < this.newsList.length; this.l++) {
        this.imgUrl[this.l] = this.sanitizer.bypassSecurityTrustUrl
          ("" + this.newsList[this.l].img_evidenza);
        console.log("C")
        console.log(this.imgUrl[this.l])
        console.log(this.newsList);

      }
      // if (this.newsList != []) {

      //   this.loader = false;
      // }

    });

  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  getImg(u) {
    return this.sanitizer.sanitize(SecurityContext.URL, `${u}`);
  }
  getNews() {
    return this.http.get<any>(this.url);
  }
}


