import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alim-lavaggio',
  templateUrl: './alim-lavaggio.component.html',
  styleUrls: ['./alim-lavaggio.component.scss']
})
export class AlimLavaggioComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0,0)
  }

}
