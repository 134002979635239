import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-contatti',
  templateUrl: './contatti.component.html',
  styleUrls: ['./contatti.component.scss']
})
export class ContattiComponent implements OnInit {
  sending = false;
  openRitorno = false;
  msg = '';
  constructor(private http: HttpClient) { }
  messageForm = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
    message: new FormControl('', Validators.required),
    termini: new FormControl(null, Validators.required),
  })

  apriRitorno(msg) {
    this.openRitorno = true;
    this.msg = msg
  }


  sendMsg() {
    if (this.messageForm.valid && this.messageForm.controls.email.value != '' && this.messageForm.controls.termini.value) {
      console.log("uno")

      this.sending = true;
      let emailObj = {
        message: this.messageForm.controls.message.value,
        from: this.messageForm.controls.email.value,
        oggetto: 'Messaggio dal sito.',
        telefono: "Nessun numero",
        categoria: "PAGINA CONTATTI"
        // categoria: this.route.split("/", 2)[1].toUpperCase
        // path: this.filePath
      }



      this.http.post("https://www.reano.net/mail", emailObj, { responseType: 'text' }).subscribe(
        (data) => {
          this.sending = false;
          this.apriRitorno("Messaggio inviato");
          setTimeout(() => {
            window.location.reload();
          }, 300)
        },
        (err) => {
          this.sending = false;
          this.apriRitorno("Qualcosa è andato storto! Riprova più tardi.");

          setTimeout(() => {
            window.location.reload();
          }, 3000)

          console.log(err);
        }
      )
    } else {
      console.log("due")
      let email = this.messageForm.controls.email.valid;
      let emailv = this.messageForm.controls.email.value;
      let msg = this.messageForm.controls.message.valid;
      let msgv = this.messageForm.controls.message.value;

      if (emailv == '' || !email) {
        console.log("error")
        document.getElementById('email').classList.add('error')
        // document.getElementById('emailp').classList.add('error')
      }
      if (msgv == '' || !msg) {
        console.log("error")
        document.getElementById('msg').classList.add('error')
        // document.getElementById('emailp').classList.add('error')
      }
      if (this.messageForm.controls.termini.value == null || !this.messageForm.controls.termini.value) {
        document.getElementById('termini').classList.add('error')
      }

    }

  }
  noerror(el) {
    if (el == 'email') {
      document.getElementById(el).classList.remove('error')
    }
    else if (el == 'msg') {
      document.getElementById(el).classList.remove('error')
    }
    else if (el == 'termini') {
      document.getElementById(el).classList.remove('error')
    }
  }
  ngOnInit() {
  }

}
