import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ambienti',
  templateUrl: './ambienti.component.html',
  styleUrls: ['./ambienti.component.scss']
})
export class AmbientiComponent implements OnInit {


  ngOnInit() {
  }
  
}
